<template>
  <FullScreenLoader :isVisible="loading" />
  <div class="runaway-page">
    <header class="collection-header">
      <h1 class="text-center" style="font-weight: 600">The Runway</h1>
    </header>
    <div style="overflow-y: scroll" class="wrapper">
      <div class="grid-container mt-3" style="">
        <div
          v-for="(image, index) in images"
          :key="index"
          :class="
            index % 6 == 0 || index % 6 == 4 ? 'grid-item-large' : 'grid-item'
          "
          @click="selectrunway(image)"
        >
          <img :src="image.image" :alt="image.vendor_name" class="image" />
          <div class="image-info">
            <small>From {{ image.vendor_name }}</small>
          </div>
        </div>
      </div>
      <div style="padding-bottom: 7vh;"></div>
    </div>
  </div>
  <RunAway
    :data="selectedrunaway"
    :showmodal="showmodal"
    @update:showmodal="showmodal = !showmodal"
  />
</template>

<script setup>
import FullScreenLoader from "@/components/common/FullScreenLoader.vue";
import RunAway from "@/components/RunAway.vue";
import { URLService } from "@/services/_url";
import { CommonService } from "@/services/commonservice";
import { ref, onMounted } from "vue";

const _images = ref([
  {
    uploader: "User A",
    src: "https://fbpros3v1.s3.ap-southeast-1.amazonaws.com/wp-content/uploads/2022/05/0304504971MRN-2_ladies_skirt_Fashion_bug_Sri_lanka.jpg",
    alt: "Image 1",
    isLarge: false,
  },
  {
    uploader: "User B",
    src: "https://fbpros3v1.s3.ap-southeast-1.amazonaws.com/wp-content/uploads/2022/05/0307007116PNS-3XL-1_Ladies_t-Shirt_Fashion_Bug_Sri_lanka.jpg",
    alt: "Image 2",
    isLarge: true,
  },
  {
    uploader: "User C",
    src: "https://fbpros3v1.s3.ap-southeast-1.amazonaws.com/wp-content/uploads/2022/05/080203205921-C5-1_Mens-T-Shirt_Fashion-Bug.jpg",
    alt: "Image 3",
    isLarge: false,
  }, // Large image
  {
    uploader: "User D",
    src: "https://fbpros3v1.s3.ap-southeast-1.amazonaws.com/wp-content/uploads/2022/04/0303500234BRN-FASHION-BUG-_-SRI-LANKA-1.jpg",
    alt: "Image 4",
    isLarge: true,
  },
  {
    uploader: "User D",
    src: "https://cdn.shopify.com/s/files/1/0022/0620/3948/files/Ernest_SS24_J15375_Final.jpg?v=1710347227",
    alt: "Image 5",
    isLarge: false,
  },
  {
    uploader: "User A",
    src: "https://publish.one37pm.net/wp-content/uploads/2024/01/GettyImages-1368793599.jpg?fit=680%2C1020",
    alt: "Image 6",
    isLarge: false,
  }, // Large image

  {
    uploader: "User C",
    src: "https://fbpros3v1.s3.ap-southeast-1.amazonaws.com/wp-content/uploads/2022/05/0304504971MRN-2_ladies_skirt_Fashion_bug_Sri_lanka.jpg",
    alt: "Image 1",
    isLarge: false,
  },
  {
    uploader: "User A",
    src: "https://fbpros3v1.s3.ap-southeast-1.amazonaws.com/wp-content/uploads/2022/05/0307007116PNS-3XL-1_Ladies_t-Shirt_Fashion_Bug_Sri_lanka.jpg",
    alt: "Image 2",
    isLarge: true,
  },
  {
    uploader: "User Z",
    src: "https://fbpros3v1.s3.ap-southeast-1.amazonaws.com/wp-content/uploads/2022/05/080203205921-C5-1_Mens-T-Shirt_Fashion-Bug.jpg",
    alt: "Image 3",
    isLarge: false,
  }, // Large image
  {
    uploader: "User E",
    src: "https://fbpros3v1.s3.ap-southeast-1.amazonaws.com/wp-content/uploads/2022/04/0303500234BRN-FASHION-BUG-_-SRI-LANKA-1.jpg",
    alt: "Image 4",
    isLarge: false,
  },
  {
    uploader: "User D",
    src: "https://cdn.shopify.com/s/files/1/0022/0620/3948/files/Ernest_SS24_J15375_Final.jpg?v=1710347227",
    alt: "Image 5",
    isLarge: true,
  },
  {
    uploader: "User Q",
    src: "https://publish.one37pm.net/wp-content/uploads/2024/01/GettyImages-1368793599.jpg?fit=680%2C1020",
    alt: "Image 6",
    isLarge: false,
  }, // Large image
  // Add more images as needed...
]);

const images = ref([]);
const selectedrunaway = ref(null);
const showmodal = ref(false);

const selectrunway = (image) => {
  showmodal.value = true;
  selectedrunaway.value = image;
};

const loading = ref(true);
onMounted(async () => {
  try {
    let url = URLService.getrunaway;
    let response = await CommonService.getrunaway(url);
    if (response.data?.length > 0) {
      images.value = response.data;
    } else {
      images.value = _images.value;
    }
    loading.value = false;
  } catch (error) {
    console.log(error);
    images.value = _images.value;
    loading.value = false;
  }
  updateAppHeight(); // Set initial height
  window.addEventListener("resize", updateAppHeight); // Update on resize
});

function updateAppHeight() {
  const adjustedHeight =
    window.innerHeight - window.innerHeight * 0.07 - window.innerHeight * 0.1;
  document.documentElement.style.setProperty(
    "--app-height",
    `${adjustedHeight}px`
  );
}
</script>

<style scoped>
.runaway-page {
  padding: 10px;
  height: 92vh;
}

.collection-header {
  display: flex;
  justify-content: center;
  margin: 0px 26px;
}

.wrapper {
  height: var(--app-height);
}

.wrapper::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto); /* Adjust min height as needed */
  gap: 1rem;
  align-items: start;
  justify-content: center;
}

.grid-container::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.grid-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}

.grid-item {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  aspect-ratio: 2 / 3;
  /* aspect-ratio: 3 / 4;  */
  /* Standard image */
}

.grid-item-large {
  grid-column: span 2;
  grid-row: span 2;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  aspect-ratio: 2 / 3; /* Large image in portrait format */
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.image-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(255, 255, 255, 0) 76%
  );
  color: white;
  text-align: left;
  border-radius: 0 0 15px 15px;
}
</style>
