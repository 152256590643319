<template>
  <FullScreenLoader :isVisible="loading" />
  <div class="order-page">
    <div v-show="!loading" v-if="bookings.length == 0" class="text-center mt-5">
      <div v-if="notloggedin == true">Please log into check your shopping history</div>
      <div v-if="notloggedin == false">No Records to show</div>
    </div>
    <div v-else class="bookings-list">
      <div
        v-for="booking in bookings"
        :key="booking.id"
        class="booking-item"
        @click="openBookingModal(booking)"
      >
        <div class="booking-info">
          <h4>Order #{{ booking.order_number }}</h4>
          <span>Quantity: {{ booking.items.length }}</span>
          <span
            >Status: {{ booking.status ?? "Order Confirmation Pending" }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <div v-if="isOpen" class="modal-overlay" @click.self="closeModal">
    <div class="modal-container">
      <button class="close-button" @click="closeModal">&times;</button>
      <div class="modal-content">
        <h5>Order #{{ selectedBooking.order_number }}</h5>
        <ul class="item-list">
          <li v-for="item in selectedBooking.items" :key="item.id" class="item">
            <img :src="item.image_url" class="image-list" alt="Product Image" />
            <div class="item-details">
              <span class="product-name">{{ item.product_name }}</span>
              <span class="product-price">Vendor: {{ item.vendorname }}</span>
              <span class="product-quantity">Qty: {{ item.quantity }}</span>
              <span class="product-price"
                >Price: LKR {{ item.item_total }}</span
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import FullScreenLoader from "@/components/common/FullScreenLoader.vue";
import { URLService } from "@/services/_url";
import { BookingService } from "@/services/bookinservice";
import { useUserStore } from "@/stores/userStore";
import { onMounted, ref } from "vue";

const userStore = useUserStore();
const loading = ref(true);
const notloggedin = ref(false);
const bookings = ref([]);
onMounted(async () => {
  if (
    userStore.user == undefined ||
    userStore.user == "" ||
    userStore.user == {} ||
    userStore.user == null
  ) {
    notloggedin.value = true;
    loading.value = false;
    return;
  } else {
    try {
      let url = URLService.getbookings.replace(
        "{userid}",
        userStore.user?.usercode
      );
      let response = await BookingService.getbookings(url);
      bookings.value = response.data.data;
      loading.value = false;
    } catch (error) {
      console.log(error);
      loading.value = false;
    }
  }
});

const selectedBooking = ref({});
const isOpen = ref(false);

const openBookingModal = (booking) => {
  selectedBooking.value = booking;
  isOpen.value = true;
};

const closeModal = () => {
  isOpen.value = false;
};
</script>
<style scoped>
.order-page {
  padding: 10px;
  overflow-y: auto;
}
.order-page::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.order-page::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}

.no-bookings-message {
  text-align: center;
  margin-top: 2rem;
  color: #666;
}

.bookings-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.booking-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border: 1px solid #333;
  border-radius: 8px;
  background-color: #99999914;
  color: #000;
}

.booking-info h4 {
  margin: 0;
  font-size: 1.2rem;
  color: #333;
}

.view-details-button {
  padding: 0.5rem 1rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.view-details-button:hover {
  background-color: #333;
}

/* Overlay for background dimming */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Main modal container */
.modal-container {
  background-color: #fff;
  color: #000;
  border-radius: 8px;
  max-width: 90%;
  width: 100%;
  padding: 1.5rem;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: 80vh;
}

/* Close button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
  z-index: 1000;
}

/* Content */
.modal-content h5 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: #333;
}

.item-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.item {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #ddd;
}

.image-list {
  width: 90px;
  height: 120px;
  border-radius: 8px;
  margin-right: 1rem;
  object-fit: cover;
}

.item-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.product-name {
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits text to two lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 2.6em; /* Adjusts the height to fit two lines */
  line-height: 1.3em; /* Adjust based on your font size */
  margin-bottom: 0.5rem;
}

.product-quantity,
.product-price {
  font-size: 0.9rem;
  color: #555;
  margin-top: 4px;
}

.item:last-child {
  border-bottom: none;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .modal-container {
    padding: 1rem;
  }

  .modal-content h5 {
    font-size: 1.1rem;
  }

  .product-name,
  .product-quantity,
  .product-price {
    font-size: 0.9rem;
  }

  .image-list {
    width: 70px;
    height: 90px;
  }
}
</style>
