// stores/cartStore.js
import { decrypteData, encrypteData } from '../services/crypt/cryptservice'
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

export const useCartStore = defineStore('cart', () => {
  const cart = ref([]);
  const orderId = ref(localStorage.getItem('orderId') || null);
  const issubmitted = ref(false);
  const loading = ref(false)

  const addProductToCart = (product) => {
    const existingProduct = cart.value
      .find(item => item.id === product.id && item.location.id == product.location.id && item.color == product.color && item.size == product.size); // TODO need to check color and size
    if (existingProduct) {
      existingProduct.quantity += product.quantity;
    } else {
      cart.value.push(product);
    }
  };

  const removeProductToCart = (product) => {
    const existingProduct = cart.value
      .find(item => item.id === product.id && item.location.id == product.location.id && item.color == product.color && item.size == product.size); // TODO need to check color and size
    if (existingProduct) {
      let index = cart.value.indexOf(existingProduct);
      cart.value.splice(index,1)
    } 
  };

  const setissubmittedfalse = () => {
    issubmitted.value = false;
  }
  const setissubmittedtrue = () => {
    issubmitted.value = true;
  }

  const clearcart = () =>{
    localStorage.removeItem('cartItems');
    cart.value = [];
  }


  const fetchOrder = async () => {
    console.log("Cart fetch");
    if (orderId.value) {
      // API Request to fetch order details using orderId
      // const order = await fetchOrderDetails(orderId.value);
      // cart.value = order.products;
      console.log('Fetching order details...');
    }
  };

  // Load cart from localStorage when the store initializes
  const loadCartFromLocalStorage = () => {
    if (localStorage.getItem('cartItems') && localStorage.getItem('cartItems') != null && localStorage.getItem('cartItems') != undefined) {
      const savedCart = decrypteData(localStorage.getItem('cartItems'));
      if(savedCart.length == 0)
        cart.value = [];
      if (savedCart && savedCart != {} && savedCart != "") {
        loading.value = true;
        cart.value = JSON.parse(savedCart);
      }
    }
  };

  const getCartItems = () => {
    return cart.value;
  };

  // Watch for changes in cartItems and orderId, and save them to localStorage
  watch(cart, (newCartItems) => {
    if(loading.value == true){
      loading.value = false;
      return;
    }
    let str = encrypteData(newCartItems);
    localStorage.setItem('cartItems', str);
  }, { deep: true });

  // watch(orderId, (newOrderId) => {
  //   localStorage.setItem('orderId', newOrderId);
  // });

  loadCartFromLocalStorage();

  return {
    cart,
    orderId,
    issubmitted,
    setissubmittedfalse,
    setissubmittedtrue,
    addProductToCart,
    fetchOrder,
    getCartItems,
    loadCartFromLocalStorage,
    clearcart,
    removeProductToCart
  };
});
