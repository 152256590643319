// src/directives/v-ripple.js

export default {
    mounted(el) {
      el.style.position = 'relative';
      el.style.overflow = 'hidden';
  
      el.addEventListener('click', (event) => {
        const ripple = document.createElement('span');
        ripple.classList.add('ripple');
  
        // Set size and position
        const rect = el.getBoundingClientRect();
        const size = Math.max(rect.width, rect.height);
        ripple.style.width = ripple.style.height = `${size}px`;
        ripple.style.left = `${event.clientX - rect.left - size / 2}px`;
        ripple.style.top = `${event.clientY - rect.top - size / 2}px`;
  
        el.appendChild(ripple);
  
        // Remove the ripple after animation
        ripple.addEventListener('animationend', () => {
          ripple.remove();
        });
      });
    },
  };
  