<template>
  <div
    class="slider-container"
    @touchstart="onTouchStart"
    @touchmove="onTouchMove"
    @touchend="onTouchEnd"
  >
    <!-- Images with Peek Effect -->
    <div
      class="slider"
      :style="{
        transform: `translateX(calc(-${
          currentIndex * 100
        }% + ${swipeOffset}px))`,
      }"
      @click="goToProductPage"
    >
        <div v-if="product.images && product.images?.length == 0" class="slide">
          <img
            src="@/assets/300x400.svg"
            alt="Slide Image"
            class="d-block w-100 border-0 rounded carousel-image"
            style="object-fit: cover"
          />
        </div>
      <div v-if="!product.images || product.images.length == 0">
        <img
          src="@/assets/300x400.svg"
          alt="Slide Image"
          class="d-block w-100 border-0 rounded"
          style="height: 250px; object-fit: cover"
        />
      </div>
      <div
        v-else
        v-for="(image, index) in product.images"
        :key="index"
        class="slide"
      >
        <img
          :src="image"
          alt="Slide Image"
          class="d-block w-100 border-0 rounded"
          style="height: 250px; object-fit: cover"
        />
      </div>
    </div>

    <div class="position-absolute bottom-0 w-100 text-white p-2">
      <button
        class="btn btn-sm"
        style="border-radius: 50px"
        @click="addToCollection"
      >
        <i class="fas fa-bookmark"></i>
      </button>
    </div>
    <!-- Navigation Dots -->
    <div class="dots">
      <span
        v-for="(image, index) in product.images"
        :key="'dot-' + index"
        :class="{ dot: true, active: currentIndex === index }"
        @click="goToSlide(index)"
      ></span>
    </div>
  </div>

  <div class="row">
    <div class="col-12" style="font-size: 0.7rem">
      <span
        class="text-truncate-1"
        style="font-weight: 700; text-transform: capitalize; font-size: 1.1rem"
        >{{ product.name }}</span
      >
      <span class="text-truncate-1" style="text-transform: capitalize">{{
        product.vendorname
      }}</span>
      <span class="mb-1"
        ><b>{{ generatePrice(product.price) }}</b></span
      >
    </div>
  </div>
</template>

<script setup>
import { useproductpageStore } from "@/stores/productPageStore";
import { defineProps, defineEmits, ref } from "vue";
import { useRouter } from "vue-router";

const currentIndex = ref(0); // Tracks the current image index
const swipeOffset = ref(0); // Tracks the swipe offset for the current swipe

// Variables to store touch positions
let startX = 0;

// Touch start event
const onTouchStart = (event) => {
  startX = event.touches[0].clientX;
  swipeOffset.value = 0; // Reset swipe offset at start
};

// Touch move event
const onTouchMove = (event) => {
  if (props.product.images.length == 0) return;
  const currentX = event.touches[0].clientX;
  const deltaX = currentX - startX;

  // Apply swipe offset to show next/previous image coming in
  swipeOffset.value = deltaX * 1.5; // Adjust 0.3 factor for smoother/faster preview of next image
};

// Touch end event
const onTouchEnd = (event) => {
  const endX = event.changedTouches[0].clientX;
  const deltaX = endX - startX;

  setTimeout(() => {
    if (deltaX > 50) {
      // Swipe Right - Go to previous image or loop to last image
      currentIndex.value =
        currentIndex.value > 0
          ? currentIndex.value - 1
          : props.product.images.length - 1;
    } else if (deltaX < -50) {
      // Swipe Left - Go to next image or loop to first image
      currentIndex.value =
        currentIndex.value < props.product.images.length - 1
          ? currentIndex.value + 1
          : 0;
    }
    swipeOffset.value = 0; // Reset swipe offset after swipe ends
  }, 10); // Loading delay (adjust as needed)
};

// Directly navigate to a specific slide on dot click
const goToSlide = (index) => {
  currentIndex.value = index;
  swipeOffset.value = 0;
};

const priceFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "LKR",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
// Setup Vue Router
const router = useRouter();
const useproductpage = useproductpageStore();

const props = defineProps({
  product: Object,
});

const generatePrice = (price) => {
  return priceFormat.format(price);
};

// START OF IMAGE CAROUSEL

// Computed property to get the current image
// const currentImage = computed(() => {
//   if (props.product.images?.length > 0)
//     return props.product.images[currentIndex.value];
//   else return "https://via.placeholder.com/300x400";
// });

// Variables for swipe gesture
// let startX = 0;
// let endX = 0;
// // Handle touch start event
// const startTouch = (event) => {
//   startX = event.touches[0].clientX;
// };
// // Handle touch move event (detect swipe direction)
// const moveTouch = (event) => {
//   endX = event.changedTouches[0].clientX;
//   const swipeDistance = startX - endX;

//   // Swipe left
//   if (swipeDistance > 70) {
//     nextImage();
//   }
//   // Swipe right
//   else if (swipeDistance < -70) {
//     prevImage();
//   }
// };

// // Function to show the next image
// const nextImage = () => {
//   if (currentIndex.value < props.product.images.length - 1) {
//     currentIndex.value++;
//   } else {
//     currentIndex.value = 0; // Loop back to the first image
//   }
// };
// // Function to show the previous image
// const prevImage = () => {
//   if (currentIndex.value > 0) {
//     currentIndex.value--;
//   } else {
//     currentIndex.value = props.product.images.length - 1; // Loop back to the last image
//   }
// };

// END OF IMAGE CAROUSEL

const emit = defineEmits(["add-to-collection"]);

const addToCollection = () => {
  emit("add-to-collection", props.product);
};

// Navigate to product page
const goToProductPage = async () => {
  await useproductpage.selectproduct(props.product);
  router.push({ name: "ProductPage", params: { id: props.product.id } });
};
</script>

<style scoped>
.overlay-text {
  background: rgba(0, 0, 0, 0.5);
}

.text-truncate-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  cursor: pointer; /* Make it look clickable */
  white-space: normal;
}

.slider-container {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

.slider {
  height: 250px;
  position: relative;
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #ddd;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: #fff;
}
</style>
