import { createApp } from 'vue'
import { IonicVue } from '@ionic/vue';
import router from './router' 
import App from './App.vue'
import { createPinia } from 'pinia';

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

// Import Bootstrap and Bootstrap's CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

import '@ionic/vue/css/core.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

const pinia = createPinia();

import rippleDirective from './directive/v-ripple';

// createApp(App).mount('#app')

const app = createApp(App).use(pinia).use(IonicVue).use(router);

app.directive('ripple', rippleDirective);

app.mount('#app');
