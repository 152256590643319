<template>
  <FullScreenLoader :isVisible="intialloading" />
  <div class="add-collection-page wrapper">
    <TopButtons :buttons="['backbtn', 'title']" :options="topvarOptions" />

    <div class="collection-form mt-2">
      <label for="collection-name">Collection Name</label>
      <input
        id="collection-name"
        v-model="collectionName"
        type="text"
        placeholder="Enter collection name"
      />

      <label>Select a Collection Image</label>
      <div class="image-grid">
        <div
          v-for="(image, index) in images"
          :key="index"
          :style="{ backgroundImage: `url(${image})` }"
          @click="selectImage(image)"
          :class="['image-option', selectedImage === image ? 'selected' : '']"
        ></div>
      </div>
    </div>


  <div class="collection-form add-collectionbtn">
    <button @click="addCollection" class="mt-2 mx-3 add-button" v-ripple>
      {{ isUpdate == true ? "Update" : "Add" }} Collection
    </button>
    <div v-if="platform == 'ios'" style="height: 4vh;"></div>
  </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import TopButtons from "@/components/common/TopButtons.vue";
import { UserService } from "@/services/userservice";
import { URLService } from "@/services/_url";
import { useUserStore } from "@/stores/userStore";
import FullScreenLoader from "@/components/common/FullScreenLoader.vue";

// Initialize store and router
const router = useRouter();
const userstore = useUserStore();

const topvarOptions = { title: "Add Collection" };
const collectionName = ref("");
const selectedImage = ref("");
const intialloading = ref(false);
const images = ref([
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/1.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/2.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/3.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/4.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/5.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/6.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/7.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/8.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/9.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/10.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/11.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/12.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/13.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/14.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/15.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/16.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/17.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/18.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/19.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/20.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/21.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/22.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/23.jpg",
  "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/24.jpg",
]);

const selectImage = (image) => {
  selectedImage.value = image;
};

const addCollection = async () => {
  if (collectionName.value && selectedImage.value) {
    if (userstore.user && userstore.user.email != undefined) {
      intialloading.value = true;
      if (isUpdate.value == true) {
        await updatecollection();
        return;
      }
      let data = {
        name: collectionName.value,
        image: selectedImage.value,
        data: null,
        m_user_id: "",
        usercode: userstore.user?.usercode,
      };
      var url = URLService.addcollection.replace(
        "{userid}",
        userstore.user?.usercode
      );
      try {
        var response = await UserService.createcollection(url, data);
        if (response.data) {
          var localCollection = localStorage.getItem("userCollection");
          if (localCollection && localCollection != "") {
            let data = JSON.parse(localCollection);
            data.push(response.data);
            localStorage.setItem("userCollection", JSON.stringify(data));
          }
        }
        intialloading.value = true;
      } catch (error) {
        intialloading.value = true;
        router.push({ name: "Collection" });
      }
    }
    intialloading.value = true;
    // Navigate back to the collections page
    router.push({ name: "Collection" });
  }
};

const updatecollection =async () => {
  if (isUpdate.value == true) {
    let data = {
      name: collectionName.value,
      image: selectedImage.value,
      data: seldata.value.data,
      m_user_id: "",
      usercode: userstore.user?.usercode,
    };
    let url = URLService.updatecollection.replace(
      "{userid}",
      userstore.user?.usercode
    );
    url = url.replace("{colid}", colid.value);
    let response = await UserService.addedtocollection(url, data);
        if (response.data) {
          var localCollection = localStorage.getItem("userCollection");
          if (localCollection && localCollection != "") {
            let data = JSON.parse(localCollection);

            let a = data.filter(x=> {return x.id == colid.value})
            if(a.length > 0){
              let ind = data.indexOf(a);
              data[ind] = response.data;
            }
            localStorage.setItem("userCollection", JSON.stringify(data));
          }
          router.go(-1)
        }
  }
};

const route = useRoute();
const isUpdate = ref(false);
const seldata = ref([])
const colid = ref(0)
onMounted(async () => {
  let id = route.params.id;
  if (id > 0) {
    isUpdate.value = true;
    colid.value = id;
    if (userstore.user && userstore.user.email != undefined) {
      var localCollection = localStorage.getItem("userCollection");
      if (localCollection && localCollection != "") {
        let collections = JSON.parse(localCollection);
        let selcoll = collections.filter((x) => {
          return x.id == id;
        });
        if (selcoll.length > 0) {
          collectionName.value = selcoll[0].name;
          selectImage(selcoll[0].image);
          seldata.value = selcoll[0].data
        }
      }
    }
  }



  try {
    const info = await Device.getInfo(); // eslint-disable-next-line
    platform.value = info.platform; // e.g., "ios", "android", "web"
  } catch (error) {
    console.error("Error getting device info:", error);
  }

updateAppHeight(); // Set initial height
window.addEventListener("resize", updateAppHeight); // Update on resize
});

import { Device } from "@capacitor/device";
const platform = ref(null)

function updateAppHeight() {
  const adjustedHeight =
    window.innerHeight - window.innerHeight * 0.07 ;
  document.documentElement.style.setProperty(
    "--app-height",
    `${adjustedHeight}px`
  );
}
</script>

<style scoped>
.add-collection-page {
  padding: 0rem 1rem;
  overflow-y: auto;
  height: 85vh;
}

.page-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 1rem;
}

.back-button {
  position: absolute;
  left: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
}

h2 {
  margin: 0;
}

.collection-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

input {
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid black;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.image-option {
  width: 100%;
  padding-top: 100%; /* Aspect ratio 1:1 */
  background-size: cover;
  background-position: center;
  border-radius: 0.5rem;
  cursor: pointer;
}

.selected {
  border: 5px solid rgb(255, 0, 34);
}

.add-button {
  background-color: black;
  color: white;
  padding: 0.75rem;
  border-radius: 1.5rem;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  margin-bottom: 20px;
}

.btn-dark {
  background-color: #000;
}

.btn-dark:hover {
  background-color: #333;
}

.add-collectionbtn {
  background-color: #fff;
  padding-top: 1%;
  bottom: 0px;
  position: sticky;
}


.wrapper {
  height: var(--app-height);
}

.wrapper::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}
</style>
