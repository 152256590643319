<template>
  <div class="collection-page">
    <header class="collection-header justify-content-around">
      <button
        class="add-collection"
        :class="isedit == true ? 'selected' : ''"
        v-if="userstore.user != undefined && userstore.user.email != undefined"
        @click="editcollection"
        style="
          width: 35px !important;
          height: 35px !important;
          font-size: 1rem !important;
          margin-top: 8px;
        "
        v-ripple
      >
        <i class="fas fa-minus"></i>
      </button>
      <h1 class="text-center" style="font-weight: 600">Your Collections</h1>
      <button
        v-ripple
        class="edit-collection"
        v-if="userstore.user != undefined && userstore.user.email != undefined"
        @click="createCollection"
        style="
          width: 35px !important;
          height: 35px !important;
          font-size: 1rem !important;
          margin-top: 8px;
        "
      >
        <i class="fas fa-plus"></i>
      </button>
    </header>

    <div
      v-if="userstore.user == undefined || userstore.user.email == undefined"
      class="text-center mt-5"
    >
      <p>Please log into create collections</p>
      <button
        class="btn btn-dark w-100"
        style="border-radius: 25px"
        @click="goToLogin"
        v-ripple
      >
        Login
      </button>
    </div>

    <div v-else class="wrapper">
      <div class="collection-grid">
        <CollectionCard
          v-for="(collection, index) in collections"
          :key="index"
          :collection="collection"
          @click="viewcollection(collection)"
          :isedit="isedit"
          @deletecollection="deletecollection(collection)"
          @editcollection="updatecollection(collection)"
        />
      </div>
      <div v-if="platform == 'ios'" style="height: 4vh"></div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import CollectionCard from "@/components/collection/CollectionCard.vue";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/userStore";
import { URLService } from "@/services/_url";
import { UserService } from "@/services/userservice";
import { CommonService } from "@/services/commonservice";
import { Device } from "@capacitor/device";
const userstore = useUserStore();

const collections = ref([]);
// const collections = ref([
//   {
//     name: "Summer Style",
//     image:
//       "https://www.wed2b.com/media/images/content-spots/designer-block/2024/07/heidi-hudson.jpg",
//   },
//   {
//     name: "Winter Wardrobe",
//     image:
//       "https://images.squarespace-cdn.com/content/v1/636b54d12e4c3228e767cddd/c43d03d6-685f-4c95-9b27-3cad9843e727/indian-wedding-photography",
//   },
//   {
//     name: "Work Attire",
//     image:
//       "https://www.billabong.com/cdn/shop/files/dresses-midi_f058da23-2b93-4244-a3b7-627c4cca0ed4_750x.jpg?v=1724101322",
//   },
//   {
//     name: "Casual Outfits",
//     image:
//       "https://insertface.com/fb/3006/korean-fashion-style-male-3006425-w7imr-fb.jpg",
//   },
// ]);

const isedit = ref(false);
const editcollection = () => {
  isedit.value = !isedit.value;
};

const router = useRouter();

const createCollection = () => {
  router.push("/collections/add");
};

const goToLogin = () => {
  router.push({ name: "Login" });
};

onMounted(async () => {
  await userstore.loadUserFromStorage();
  if (userstore.user && userstore.user.email != undefined) {
    var localCollection = localStorage.getItem("userCollection");
    if (localCollection && localCollection != "") {
      collections.value = JSON.parse(localCollection);
    }

    var url = URLService.getcollections.replace(
      "{userid}",
      userstore.user?.usercode
    );
    var response = await UserService.getcollections(url);
    if (response.status == 200) {
      if (response.data) {
        collections.value = response.data;
        localStorage.setItem(
          "userCollection",
          JSON.stringify(collections.value)
        );
      }
    } else {
      router.go(-1);
    }
  }

  try {
    const info = await Device.getInfo(); // eslint-disable-next-line
    platform.value = info.platform; // e.g., "ios", "android", "web"
  } catch (error) {
    console.error("Error getting device info:", error);
  }

  updateAppHeight(); // Set initial height
  window.addEventListener("resize", updateAppHeight); // Update on resize
});

const viewcollection = (collection) => {
  router.push({ name: "ViewCollection", params: { id: collection.id } });
};

const platform = ref(null);

const deletecollection = (collection) => {
  var localCollection = localStorage.getItem("userCollection");
  if (localCollection && localCollection != "") {
    let data = JSON.parse(localCollection);
    let selectedCollections = data.filter((x) => {
      return x.id == collection.id;
    });
    if (selectedCollections.length > 0) {
      let selectedindex = data.indexOf(selectedCollections[0]);
      data.splice(selectedindex, 1);
      collections.value = data;
      localStorage.setItem("userCollection", JSON.stringify(data));

      try {
        let deletecollectionUrl = URLService.deletecollection.replace(
          "{colid}",
          collection.id
        );
        let coldata = {
          user_id: collection.m_user_id,
        };
        CommonService.deleteCollection(deletecollectionUrl, coldata);
      } catch (error) {
        console.log(error);
      }
    }
  }
};

const updatecollection = (collection) => {
  router.push({ name: "UpdateCollection", params: { id: collection.id } });
};

function updateAppHeight() {
  const adjustedHeight =
    window.innerHeight - window.innerHeight * 0.07 - window.innerHeight * 0.15;
  document.documentElement.style.setProperty(
    "--app-height",
    `${adjustedHeight}px`
  );
}
</script>

<style scoped>
.collection-page {
  padding: 10px;
  height: 92vh;
}
.collection-page::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.collection-page::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}

.collection-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 0 10px 10px;
}

.collection-header h2 {
  margin: 0;
}

.edit-collection {
  /* position: absolute;
  right: 10px; */
  background-color: black;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.add-collection.selected {
  background-color: #c7c7c7;
}
.add-collection {
  /* position: absolute;
  left: 10px; */
  background-color: black;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.collection-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.collection-card {
  background-size: cover;
  background-position: center;
  position: relative;
}

.collection-card-name {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.5rem;
}

.wrapper {
  height: var(--app-height);
  overflow-y: scroll;
}

.wrapper::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}
</style>

<!-- 
<template>
    <div class="collection-page">
        <h1>Your Collections</h1>
        <div class="collection-grid">
            <CollectionCard v-for="collection in collections" :key="collection.id" :collection="collection"
                @click="navigateToCollection(collection.id)" />
        </div>
    </div>
</template>

<script setup>
import CollectionCard from '@/components/collection/CollectionCard.vue';
import { ref } from 'vue';

const collections = ref([
    { id: 1, name: 'Saved Collection', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1fWWsa9UmR0TFl9eJV3BPRqhan1GGR5lwSg&s' },
    { id: 2, name: 'Weddings', image: 'https://images.squarespace-cdn.com/content/v1/636b54d12e4c3228e767cddd/c43d03d6-685f-4c95-9b27-3cad9843e727/indian-wedding-photography' },
    // Add more collections here
]);

const navigateToCollection = (collectionId) => {
    /* eslint-disable */
    var a = collectionId;
    // Logic for navigating to collection details page
};
</script>

<style scoped>
.collection-page {
    padding: 1rem;
}

.collection-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}
</style> -->
