<template>
  <div>
    <div class="d-flex justify-content-center align-items-center vh-100">
      <div v-if="loading" class="text-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <p class="mt-3">Payment Processing...</p>
      </div>

      <div v-else-if="status === 'paymentconfirmed'" class="text-center px-4">
        <i class="fas fa-check-circle text-success" style="font-size: 6rem"></i>
        <h3 class="mt-3">Payment Successful!</h3>
        <p>Thank you for your purchase.</p>
        <p style="font-size: 0.8rem; color: grey">
          Please wait for redirection
        </p>
      </div>

      <div v-else-if="status === 'paymentfailed'" class="text-center px-4">
        <i class="fas fa-times-circle text-danger" style="font-size: 6rem"></i>
        <h3 class="mt-3">Payment Failed</h3>
        <p v-if="paymentfailedmessage != ''">{{ paymentfailedmessage }}</p>
        <p>There was an issue with your payment. Please try again.</p>

        <p style="font-size: 0.8rem; color: grey">
          Please wait for redirection
        </p>
      </div>
    </div>

    <ion-toast
      id="toast"
      trigger="open-toast"
      :is-open="isOpen"
      message="Payment Failed, Please retry"
      :duration="3000"
    ></ion-toast>
  </div>
</template>

<script setup>
import { URLService } from "@/services/_url";
import { CommonService } from "@/services/commonservice";
import { ref, onMounted, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router"; // Use Vue Router to redirect.
import { IonToast } from "@ionic/vue";
import { Browser } from "@capacitor/browser";
import { Device } from "@capacitor/device";
const router = useRouter();
const route = useRoute();
// const targetBackendPage = "/success"; // Replace with your backend page URL.
const loading = ref(true);
const isOpen = ref(false);
const totaltimespent = ref(0)
const paymentfailedmessage = ref('')


onUnmounted(() => {
  clearInterval(intervalId.value);
});

const status = ref(null);
const intervalId = ref(null);
const fetchPaymentStatus = async () => {
  totaltimespent.value += 3000;
  if(totaltimespent.value >= 360000){
    status.value = 'paymentfailed';
    paymentfailedmessage.value = "Payment Session timeout, please try again later"
    clearInterval(intervalId.value);
    setTimeout(() => {
      router.push({name: 'CheckoutPage'});
    }, 5000);
  }
  try {
    let url = URLService.getpaymentstatus.replace(
      "{id}",
      route.params.ordernumber
    );
    const response = await CommonService.getpaymentstatus(url); // Replace with your API endpoint
    const paymentStatus = response.data.data; // Assume API returns `{ status: "success" | "failed" }`

    if (paymentStatus === "paymentconfirmed" || paymentStatus === "paymentfailed") {
      status.value = paymentStatus;
      loading.value = false;
      clearInterval(intervalId.value);
      setTimeout(() => {
        router.push({ name: 'successpage'})
      }, 5000);
    }
  } catch (error) {
    console.error("Error fetching payment status:", error);
    // You might want to handle errors, like retrying or showing an error message
  }
};

const platform = ref("web");
onMounted(async () => {
  let id = route.params.ordernumber;
  if (!route.params.ordernumber) {
    isOpen.value = true;
    setTimeout(() => {
      router.go(-1);
    }, 1500);
    return;
  }

  const info = await Device.getInfo();
  platform.value = info.platform; // e.g., "ios", "android", "web"

  let url = URLService.getpaymentlink.replace("{id}", id);
  try {
    let response = await CommonService.getpaymentlink(url);
    if (response.status >= 200 && response.status < 400) {
      if (platform.value === "ios" || platform.value === "android") {
        await Browser.open({ url: response.data.data });
      } else {
        window.open(response.data.data, "_blank");
      }
    } else {
      isOpen.value = true;
      setTimeout(() => {
        router.go(-1);
      }, 1500);
    }
  } catch (error) {
    console.log(error);
    router.go(-1);
  }

  intervalId.value = setInterval(fetchPaymentStatus, 3000);
});
</script>

<style scoped>
.vh-100 {
  height: 100vh;
}
</style>

<script>
// import { URLService } from "@/services/_url";
// const paymentUrl = ref(""); // Replace with the gateway URL.
// const paymentIframe = ref(null);

// const checkIframePage = () => {
//   try {
//     const iframeWindow = paymentIframe.value.contentWindow;
//     const iframeLocation = iframeWindow.location.href;

//     console.log("Current Iframe URL:", iframeLocation); // Optional: For debugging.

//     // Check if the iframe page matches your backend success page.
//     if (iframeLocation.includes(targetBackendPage)) {
//       // alert();
//       console.log(router);
//       //   router.push("/success"); // Redirect to the desired Vue page.
//     }
//   } catch (error) {
//     console.warn("Cross-origin restriction:", error);
//   }
// };

// const handleMessage = (event) => {
//   if (event.data === "payment-success") {
//     setTimeout(() => {
//       console.log("Payment succeeded!");
//       router.push({ name: "successpage" });
//     }, 4000); // Redirect to success page.
//   }
// };

// onUnmounted(() => {
//   window.removeEventListener("message", handleMessage);
// });


  // window.addEventListener("message", handleMessage);
</script>
