<template>
  <div class="filter-selection">
    <h2>Select {{ filterType }}</h2>
    <div v-for="(option, index) in options" :key="option">
      <div
        class="parent-category"
        :class="
          selectedOptions.indexOf(option.name) >= 0 ||
          selectedOptions.indexOf(option) >= 0 
            ? 'selected'
            : ''
        "
        @click="toggleCategory(index, option)"
      >
        <span v-if="subOptionsAvailable">{{ option.name }}</span>
        <span v-else>{{ option }}</span>

        <div v-if="subOptionsAvailable">
          <span v-if="expandedIndex === index">▲</span>
          <span v-else>▼</span>
        </div>
      </div>
      <div v-if="expandedIndex === index">
        <div
          class="sub-category"
          :class="
            selectedOptions.indexOf(option.name) >= 0 
              ? 'selected'
              : ''
          "
          @click="selectSubCategory(option.name)"
          v-for="option in option.children"
          :key="option.id"
        >
          <span v-if="subOptionsAvailable">{{ option.name }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-actions">
    <button @click="cancelSelection">Cancel</button>
    <button @click="doneSelection">Done</button>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { defineProps } from "vue";

// // Check if a subcategory is selected
// const isSelected = (sub) => {
//     return selectedSubCategory.value?.id === sub.id;
// };

const props = defineProps({
  filterType: String,
  options: Array,
  onSelect: Function,
  onCancel: Function,
  tempselection: Object,
});
onMounted(() => {
  var data = JSON.parse(JSON.stringify(props.options));
  if (
    data.filter((x) => {
      return x.children != undefined && x.children.length > 0;
    }).length > 0
  )
    subOptionsAvailable.value = true;

    if(props.tempselection[props.filterType] && props.tempselection[props.filterType].length > 0){
      props.tempselection[props.filterType].forEach(element => {
        selectedOptions.value.push(element)
      });
    }

});

const selectedOptions = ref([]);
const subOptionsAvailable = ref(false);

const doneSelection = () => {
  props.onSelect(selectedOptions.value);
};

const cancelSelection = () => {
  props.onCancel();
};

const toggleCategory = (index, option) => {
  let removedata = subOptionsAvailable.value ? option.name : option;
  expandedIndex.value = expandedIndex.value === index ? null : index;
  if (selectedOptions.value.indexOf(removedata) < 0)
    selectedOptions.value.push(removedata);
  else
    selectedOptions.value.splice(selectedOptions.value.indexOf(removedata), 1);
};

const selectSubCategory = (sub) => {
  if (selectedOptions.value.indexOf(sub) < 0) selectedOptions.value.push(sub);
  else selectedOptions.value.splice(selectedOptions.value.indexOf(sub), 1);
};

// State for expanded categories and selected subcategory
const expandedIndex = ref(null); // Index of the currently expanded parent
</script>

<style scoped>
.category-list {
  width: 100%;
  max-width: 300px;
  margin: auto;
}

.parent-category {
  background-color: #f4f4f4;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin-bottom: 0.3rem;
}

.sub-category {
  background-color: #f5f5f575;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin-bottom: 0.3rem;
}

.parent-category.selected {
  background-color: #000000;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #1f1f1f;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin-bottom: 0.3rem;
  color: #fff;
}

.sub-category.selected {
  background-color: #000000;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #1f1f1f;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin-bottom: 0.3rem;
  color: #fff;
}

.subcategories {
  background-color: #fff3f3;
  margin: 0;
  padding: 10px;
  list-style-type: none;
}

.subcategories li {
  margin-top: 5px;
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.subcategories li.selected {
  background-color: #000000;
  color: #fff;
}

.actions {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

button {
  padding: 8px 16px;
  cursor: pointer;
}

.filter-selection {
  background: white;
  border-radius: 10px;
  /* Rounded corners */
  padding: 20px;
  width: 100%;
  height: 80%;
  max-width: 500px;
  overflow-y: scroll;
  /* Maximum width for larger screens */
}

.options {
  margin: 10px 0;
  max-height: 400px;
  /* Limit height for scrolling */
  overflow-y: auto;
  /* Enable vertical scroll if necessary */
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  /* Space buttons apart */
  margin-top: 20px;
}

button {
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  border: 1px solid black;
  color: black;
  background-color: white !important;
}

.btn-apply {
  background-color: white;
  color: black;
}

.btn-cancel {
  background-color: black;
  color: white;
}

button:hover {
  opacity: 0.8;
}
</style>
