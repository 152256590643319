<template>
  <div
    class="product-card position-relative"
    @click="goToProductPage"
    :style="{ width: setwidth }"
  >
    <div class="image-container">
      <img :src="currentImage" class="img-fluid" :alt="product.name" />
    </div>
    <div class="row">
      <div class="col-12" style="font-size: 0.8rem">
        <div>
          <span class="text-truncate-2">{{ product.name }}</span>
        </div>
        <span class="mb-1"
          ><b>{{ generatePrice(product.price) }}</b></span
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { useproductpageStore } from "@/stores/productPageStore";
import { defineProps, computed } from "vue";
import { useRouter } from "vue-router";
const priceFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "LKR",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

// Setup Vue Router
const router = useRouter();
const useproductpage = useproductpageStore();

const props = defineProps({
  product: Object,
  temp: Boolean,
});

// START OF IMAGE CAROUSEL

// Computed property to get the current image
const currentImage = computed(() => {
  if (
    Array.isArray(props.product.generalurl) &&
    props.product.generalurl?.length > 0
  ) {
    return props.product.generalurl[0];
  } else if (props.product.generalurl?.length > 0) {
    return props.product.generalurl;
  } else return "https://via.placeholder.com/300x400";
});

const setwidth = computed(() => {
  if (props.temp == false) return "100% !important";
  else return "150px";
});
// END OF IMAGE CAROUSEL

// Navigate to product page
const goToProductPage = async () => {
  await useproductpage.selectproduct(props.product);
  router.push({ name: "ProductPage", params: { id: props.product.id } });
};

const generatePrice = (price) => {
  return priceFormat.format(price);
};
</script>

<style scoped>
.text-truncate-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  cursor: pointer; /* Make it look clickable */
  white-space: normal;
}
.product-card {
  width: 100%;
  position: relative;
  overflow: hidden;
  min-width: 150px;
  flex: 0 0 auto;
}

.image-container {
  /* Aspect ratio 4:3 */
  width: 100%;
  padding-top: 125%;
  /* 4:3 aspect ratio (height / width * 100 = 75%) */
  position: relative;
  overflow: hidden;
  aspect-ratio: 2/3;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensures the image covers the container while keeping aspect ratio */
}

.overlay-text {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 76%
  );
  border-radius: 0px 0px 10px 10px;
}

.img-fluid {
  border-radius: 10px;
}

.product-card {
  width: 100%;
  position: relative;
  overflow: hidden;
  min-width: 150px;
  flex: 0 0 auto;
}

.image-container {
  width: 100%;
  padding-top: 125%;
  position: relative;
  overflow: hidden;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-info {
  background-color: #ffffff;
  border-radius: 0 0 10px 10px;
}
</style>
