<template>
  <div class="cart-page mb-5 wrapper ">
    <h1 class="text-center" style="font-weight: 600;">My Wardrobe</h1>

    <!-- Tabs for navigation -->
    <CartTabs v-model="selectedSection" />

    
    <CartSummary2
      v-if="_cartitems?.length > 0 && selectedSection == 0"
      :total="total"
      :discount="discount"
      :subtotal="subtotal"
      @checkout="handleCheckout"
      class="cart-summary"
    />
    <div
      v-if="selectedSection == 0 || selectedSection == undefined"
      class="cart-content flex-grow-1 overflow-auto"
    >    
      <div
        v-for="(items, vendor) in groupedItems"
        :key="vendor"
        class="vendor-group mb-4"
      >
        <div class="cart-items border rounded p-2">
          <CartItem
            v-for="(item, index) in items"
            :key="index"
            :item="item"
            :index="index"
            @removeItem="removeItem"
            @updateQuantity="updateQuantity"
            @updateSize="updateSize"
          />
        </div>
      </div>
    </div>

    <CartSummary
      v-if="_cartitems?.length > 0 && selectedSection == 0"
      :total="total"
      :discount="discount"
      :subtotal="subtotal"
      @checkout="handleCheckout"
      class="cart-summary"
    />
    <OrderPage v-if="selectedSection == 1" />
    <OrderPage v-if="selectedSection == 2" />
    <ReturnPage v-if="selectedSection == 3" />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import CartSummary from "@/components/cart/CartSummary.vue";
import CartItem from "@/components/cart/CartItem.vue";
import CartTabs from "@/components/cart/CartTabs.vue";
import { useCartStore } from "@/stores/cartStore";
import { useRouter } from "vue-router";
import OrderPage from "./OrderPage.vue";
import ReturnPage from "./ReturnPage.vue";
import CartSummary2 from "@/components/cart/CartSummary2.vue";

const selectedSection = ref("");

const cartStore = useCartStore();
const priceFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "LKR",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
const _cartitems = ref([]);
const cartItems = ref([
  {
    name: "Cable Knit Oversized Sweater",
    price: 39,
    size: null,
    quantity: 1,
    vendor: "Edited",
    estimatedArrival: "20 Sep - 24 Sep",
    imageUrl: "https://example.com/sweater.jpg",
  },
  {
    name: "Strapless Mini Dress Blue",
    price: 64,
    size: null,
    quantity: 1,
    vendor: "Princess Polly",
    estimatedArrival: "20 Sep - 25 Sep",
    imageUrl: "https://example.com/dress.jpg",
  },
  {
    name: "Cable Knit Oversized Sweater",
    price: 39,
    size: null,
    quantity: 1,
    vendor: "Edited",
    estimatedArrival: "20 Sep - 24 Sep",
    imageUrl: "https://example.com/sweater.jpg",
  },
  {
    name: "Strapless Mini Dress Blue",
    price: 64,
    size: null,
    quantity: 1,
    vendor: "Princess Polly",
    estimatedArrival: "20 Sep - 25 Sep",
    imageUrl: "https://example.com/dress.jpg",
  },
]);
const router = useRouter();

const subtotal = computed(() => {
  let value = _cartitems.value.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );
  let discountItems = _cartitems.value.filter((x) => {
    return x.discount && x.discount > 0;
  });
  let dvalue = discountItems.reduce(
    (acc, item) => acc + (item.price - item.discount) * item.quantity,
    0
  );
  return priceFormat.format(value - dvalue);
});
const total = computed(() => {
  let value = _cartitems.value.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );
  return priceFormat.format(value);
});
const discount = computed(() => {
  let discountItems = _cartitems.value.filter((x) => {
    return x.discount && x.discount > 0;
  });
  let value = discountItems.reduce(
    (acc, item) => acc + (item.price - item.discount) * item.quantity,
    0
  );
  return priceFormat.format(value);
});

const removeItem = ({ item }) => {
  cartStore.removeProductToCart(item);
};

const handleCheckout = () => {
  router.push({ name: "CheckoutPage" });
};

// Update the quantity of a cart item
const updateQuantity = ({ index, quantity }) => {
  cartItems.value[index].quantity = quantity;
};

onMounted(() => {
  _cartitems.value = cartStore.cart;

updateAppHeight(); // Set initial height
window.addEventListener("resize", updateAppHeight); // Update on resize
});

const groupedItems = computed(() => {
  return _cartitems.value.reduce((groups, item) => {
    const vendor = item.vendorname;
    if (!groups[vendor]) {
      groups[vendor] = [];
    }
    groups[vendor].push(item);
    return groups;
  }, {});
});

// Update the size of a cart item
const updateSize = ({ index, size }) => {
  cartItems.value[index].size = size;
};


function updateAppHeight() {
  const adjustedHeight =
    window.innerHeight - window.innerHeight * 0.07 ;
  document.documentElement.style.setProperty(
    "--app-height",
    `${adjustedHeight}px`
  );
}
</script>

<style scoped>
.cart-page {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
  height: 93vh;
  display: flex;
  flex-direction: column;
}
.cart-page::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.cart-content {
  overflow-y: auto;
  height: 80vh;
  margin-top: 10px;
}
.cart-content::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}
.cart-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}

.cart-page::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}

.vendor-tab {
  display: inline-block;
  background-color: #343a40;
  /* Dark background for contrast */
  color: white;
  border-radius: 10px 10px 0px 0px !important;
  margin-bottom: -10px;
  font-size: 0.875rem;
  /* Small font size */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for elevation */
}

.vendor-group {
  border-radius: 5px;
  overflow: hidden;
}

.cart-items {
  border: solid 1px black !important;
}

       
.wrapper {
  height: var(--app-height);
}

.wrapper::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}
</style>
