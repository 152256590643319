<template>
  <div
    class="slider-container"
    @touchstart="onTouchStart"
    @touchmove="onTouchMove"
    @touchend="onTouchEnd"
  >
    <!-- Images with Peek Effect -->
    <div
      class="slider"
      :style="{
        transform: `translateX(calc(-${
          currentIndex * 100
        }% + ${swipeOffset}px))`,
      }"
    >
      <div v-for="(image, index) in images" :key="index" class="slide">
        <img
          :src="image"
          alt="Slide Image"
          class="d-block w-100 border rounded slide-image"
          style="object-fit: fill"
        />
      </div>
    </div>

    <!-- Navigation Dots -->
    <div class="dots">
      <span
        v-for="(image, index) in images"
        :key="'dot-' + index"
        :class="{ dot: true, active: currentIndex === index }"
        @click="goToSlide(index)"
      ></span>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";

// Array of images
const images = ref([]);

const currentIndex = ref(0); // Tracks the current image index
const loading = ref(false); // Shows loading indicator during transitions
const swipeOffset = ref(0); // Tracks the swipe offset for the current swipe

// Variables to store touch positions
let startX = 0;
let autoSlideInterval = null;

// Touch start event
const onTouchStart = (event) => {
  startX = event.touches[0].clientX;
  swipeOffset.value = 0; // Reset swipe offset at start
};

// Touch move event
const onTouchMove = (event) => {
  const currentX = event.touches[0].clientX;
  const deltaX = currentX - startX;

  // Apply swipe offset to show next/previous image coming in
  swipeOffset.value = deltaX * 4; // Adjust 0.3 factor for smoother/faster preview of next image
};

// Touch end event
const onTouchEnd = (event) => {
  const endX = event.changedTouches[0].clientX;
  const deltaX = endX - startX;

  if (loading.value) return; // Prevent swipe during loading

  loading.value = true; // Show loading overlay

  setTimeout(() => {
    if (deltaX > 50) {
      // Swipe Right - Go to previous image or loop to last image
      currentIndex.value =
        currentIndex.value > 0
          ? currentIndex.value - 1
          : images.value.length - 1;
    } else if (deltaX < -50) {
      // Swipe Left - Go to next image or loop to first image
      currentIndex.value =
        currentIndex.value < images.value.length - 1
          ? currentIndex.value + 1
          : 0;
    }
    swipeOffset.value = 0; // Reset swipe offset after swipe ends
    loading.value = false; // Hide loading overlay
    // Restart auto-slide after user interaction
    startAutoSlide();
  }, 10); // Loading delay (adjust as needed)
};

// Directly navigate to a specific slide on dot click
const goToSlide = (index) => {
  if (loading.value) return;
  loading.value = true;
  currentIndex.value = index;
  swipeOffset.value = 0;
  setTimeout(() => {
    loading.value = false;
    startAutoSlide(); // Restart auto-slide after manual slide
  }, 500); // Reset loading state after transition
};

const startAutoSlide = () => {
  if (autoSlideInterval) {
    clearInterval(autoSlideInterval); // Clear any existing interval
  }

  autoSlideInterval = setInterval(() => {
    currentIndex.value =
      currentIndex.value < images.value.length - 1 ? currentIndex.value + 1 : 0;
  }, 5000); // Change to next image every 3 seconds
};

// Start the auto-slide when the component is mounted
onMounted(() => {
  var a = localStorage.getItem("images_banner");
  if (a && a != undefined && a != "" && a != "[]") {
    images.value = JSON.parse(a);
  } else {
    images.value = [
      "./300_400.png",
      "./300_400.png",
      "./300_400.png",
      "./300_400.png",
      "./300_400.png",
    ];
  }

  startAutoSlide();
});

// Cleanup the interval when the component is unmounted
onBeforeUnmount(() => {
  clearInterval(autoSlideInterval);
});
</script>

<style scoped>
.slider-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 230px;
  border-radius: 10px;
  border: 0px !important;
  padding-bottom: 2rem;
}

.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
  border: 0px !important;
}

.slide {
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px !important;
}

.slide img {
  width: 100%;
  object-fit: cover;
  border: 0px !important;
}
.dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ddd;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: #fff;
}

.slide-image {
  height: 230px;
}
/* Adjust height for 7-inch tablets (around 600px width in portrait mode) */
@media (min-width: 600px) and (max-width: 800px) {
  .slider-container {
    height: 300px; /* Height for 7-inch tablets */
  }
  .slide-image {
    height: 300px;
  }
}

/* Adjust height for 10-inch tablets (around 800px width in portrait mode) */
@media (min-width: 800px) and (max-width: 1000px) {
  .slider-container {
    height: 400px; /* Height for 10-inch tablets */
  }
  .slide-image {
    height: 400px;
  }
}

/* Adjust height for 10-inch tablets (around 800px width in portrait mode) */
@media (min-width: 1000px) {
  .slider-container {
    height: 600px; /* Height for 10-inch tablets */
  }
  .slide-image {
    height: 600px;
  }
}
</style>
