<template>
  <div class="cart-item mb-1 d-flex rounded">
    <!-- Image Column -->
    <div class="image-column">
      <img
        :src="
          item.images != undefined
            ? item.images[0]
            : 'https://via.placeholder.com/300x400'
        "
        alt="Product Image"
        class="item-image rounded"
      />
    </div>

    <!-- Details Column -->
    <div
      class="item-details flex-grow-1 ms-3 d-flex flex-column justify-content-between"
    >
      <div>
        <h5 class="item-name text-truncate-2">
          {{ item.name }}
        </h5>
      </div>

      <div class="item-details d-flex flex-column justify-content-between">
        <!-- Size Selector -->
        <div class="size-selector">
          <h6 style="margin: 0px; padding: 0px" class="text-truncate-1">
            {{ item.vendorname }}
          </h6>
        </div>
        <div class="size-selector">
          <label
            >Size:
            <span style="margin: 0px; padding: 0px">{{
              item.size
            }}</span></label
          >
        </div>
      </div>

      <!-- Quantity Controls & Price -->
      <div class="d-flex align-items-center">
        <div class="quantity-container">
          <div class="quantity-input">
            <label class="quantity-label">Qty</label>
            <button @click="decreaseQuantity(item)" class="qty-btn qty-left">
              -
            </button>
            <span class="qty-display"
              ><input
                v-model="localQuantity"
                @change="updateQuantity"
                type="number"
                min="1"
                class="qty-input disabled no-spinners"
            /></span>
            <button @click="increaseQuantity(item)" class="qty-btn qty-right">
              +
            </button>
          </div>
        </div>
        <!-- 
        <button
          @click="decreaseQuantity(item)"
          class="btn btn-outline-dark btn-sm"
        >
          -
        </button>
        <input
          type="number"
          v-model="localQuantity"
          @change="updateQuantity"
          disabled
          class="form-control text-center mx-2"
          style="width: 50px"
        />
        <button
          @click="increaseQuantity(item)"
          class="btn btn-outline-dark btn-sm"
        >
          +
        </button> -->
        <span class="ms-auto text-danger" @click="deleteitem(item)">
          <i class="fa-solid fa-trash"></i>
        </span>
      </div>

      <span class="text-muted" style="font-size: 11px"
        >Estimated arrival: {{ item.estimatedArrival }}</span
      >

      <div class="item-price">
        <!-- <span style="font-size: 12px"
          >LKR {{ price }} * {{ item.quantity }}</span
        > -->
        <span style="float: right; font-weight: 700">{{ total }}</span>
      </div>
    </div>
  </div>

  <div
    v-if="showModal == true"
    class="custom-modal-overlay"
    @click.self="closeModal"
  >
    <div class="custom-modal">
      <div class="modal-header">
        <h5>Confirm Action</h5>

        <span
          class="close-button"
          @click="closeModal"
          style="
            cursor: pointer;
            padding: 0.3rem;
            right: 2rem;
            top: 1.1rem;
            font-size: x-large;
          "
          >&times;</span
        >
      </div>
      <div class="modal-body">
        <p>Do you want to delete the item?</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary border-round" @click="closeModal">
          No
        </button>
        <button class="btn btn-danger border-round" @click="confirmation">
          Yes
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, watch, defineEmits, computed } from "vue";

const priceFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "LKR",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
const props = defineProps({
  item: Object,
  index: Number,
});

// Emits
const $emit = defineEmits(["removeItem", "updateQuantity", "updateSize"]);

const localQuantity = ref(props.item.quantity);

// Watch the prop and update the local quantity when the prop changes
watch(
  () => props.item.quantity,
  (newQuantity) => {
    localQuantity.value = newQuantity;
  }
);

const total = computed(() => {
  let value = props.item.quantity * price.value;
  return priceFormat.format(value);
});

// const setcolor = computed(() => {
//     let items = props.item.actual_colors.filter(x=> {return x.name.toLowerCase() == props.item.color.toLowerCase()})
//     return items.length > 0 ? items[0].color: ''
// });

const price = computed(() => {
  if (props.item?.discount && props.item?.discount > 0) {
    return props.item?.discount;
  } else {
    return props.item?.price;
  }
});

const increaseQuantity = (item) => {
  if (item.quantity >= 1) {
    item.quantity += 1;
  }
};

const decreaseQuantity = (item) => {
  if (item.quantity > 1) {
    item.quantity -= 1;
  }
  if (item.quantity < 0) {
    item.quantity = 1;
  }
};

// Emit the updated quantity to the parent
const updateQuantity = () => {
  const newQuantity = localQuantity.value;
  $emit("updateQuantity", { index: props.index, quantity: newQuantity });
};

const showModal = ref(false);
const selecteditem = ref(null);
const deleteitem = (item) => {
  selecteditem.value = item;
  console.log(props.index);
  showModal.value = true;
};
const closeModal = () => {
  showModal.value = false;
};
const confirmation = () => {
  showModal.value = false;
  $emit("removeItem", { item: selecteditem.value });
};
</script>

<style scoped>
/* .cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-image {
    width: 80px;
    height: 100px;
    object-fit: cover;
}

.item-details {
    flex-grow: 1;
}

.item-price {
    font-size: 1.2em;
    font-weight: bold;
} */

.cart-item {
  display: flex;
  align-items: stretch;
}

/* Image column with a larger image */
.image-column {
  flex: 0 0 150px; /* Adjust size as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-image {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

/* Ensure name fits within two lines */
.text-truncate-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  cursor: pointer; /* Make it look clickable */
  white-space: normal;
} /* Ensure name fits within two lines */
.text-truncate-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  cursor: pointer; /* Make it look clickable */
  white-space: normal;
  text-overflow: ellipsis;
}

/* Adjust item details */
.item-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item-price h5 {
  text-align: right; /* Align price to the right */
}

.size-selector {
  width: 100%;
  float: left;
}

.quantity-container {
  display: flex;
  justify-content: space-between; /* Align label to left and controls to right */
  align-items: center;
  width: 100%;
  margin-right: 10px;
  font-size: 14px;
}

.quantity-label {
  font-weight: bold;
  margin-right: 5px;
  font-size: 14px;
}

.quantity-input {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.qty-btn {
  background-color: #e0e0e0; /* Light gray for button background */
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qty-display {
  font-size: 16px;
  font-weight: bold;
}

.quantity-input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.qty-btn {
  background-color: white;
  padding: 10px;
  cursor: pointer;
}

.qty-input {
  border: none;
  width: 30px;
  font-weight: 700;
  text-align: center;
  line-height: 1.5;
  box-shadow: none; /* Remove shadow */
  outline: none; /* Remove outline */
}
.qty-input:focus {
  outline: none; /* Ensure no outline on focus */
  box-shadow: none; /* Ensure no shadow on focus */
}
.qty-input::-moz-focus-inner {
  border: none;
}

.qty-left,
.qty-right {
  border-radius: 50px;
  background-color: #e0e0e0;
  font-weight: 800;
}
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal container */
.custom-modal {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease;
}

/* Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Close button */
.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #888;
  cursor: pointer;
}

/* Body and Footer */
.modal-body {
  margin-bottom: 20px;
  text-align: center;
}
.modal-footer {
  display: flex;
  justify-content: space-between;
}
.modal-footer .btn {
  flex: 1;
  margin: 0 5px;
}

/* Animation for modal */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
