<!-- CollectionCard.vue -->
<template>
  <div class="collection-card" @click="selectcollection">
    <div
      class="collection-image"
      :style="{ backgroundImage: `url(${collection.image})` }"
    >
      <div class="collection-edit" v-if="isedit == true">
        <button
          class="edit"
          style="
            width: 25px !important;
            height: 25px !important;
            font-size: 0.7rem !important;
            margin-top: 8px;
          "
          @click.stop="editcollection"
        >
          <i class="fa-solid fa-pen"></i>
        </button>
        <button
          class="delete"
          style="
            width: 25px !important;
            height: 25px !important;
            font-size: 0.7rem !important;
            margin-top: 8px;
          "
          @click.stop="deletecollection"
        >
          <i class="fa-solid fa-trash-can"></i>
        </button>
      </div>
      <div class="collection-name">{{ collection.name }}</div>
    </div>
  </div>

  <div
    v-if="showModal == true"
    class="custom-modal-overlay"
    @click.self="closeModal"
  >
    <div class="custom-modal">
      <div class="modal-header">
        <h5>Confirm Action</h5>

        <span
          class="close-button"
          @click="closeModal"
          style="
            cursor: pointer;
            padding: 0.3rem;
            right: 2rem;
            top: 1.1rem;
            font-size: x-large;
          "
          >&times;</span
        >
      </div>
      <div class="modal-body">
        <p>Do you want to delete the collection?</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary border-round" @click="closeModal">
          No
        </button>
        <button class="btn btn-danger border-round" @click="confirmation">
          Yes
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";

defineProps({
  collection: Object,
  isedit: Boolean,
});

const emit = defineEmits(["click", "deletecollection", "editcollection"]);

const selectcollection = () => {
  emit("click");
};

const editcollection = () => {
  emit("editcollection");
};
const deletecollection = () => {
  showModal.value = true;
};

const showModal = ref(false);
const closeModal = () => {
  showModal.value = false;
};
const confirmation = () => {
  showModal.value = false;
  emit("deletecollection");
};
</script>

<style scoped>
.collection-card {
  position: relative;
  padding-bottom: 75%;
  /* 4:3 aspect ratio */
  cursor: pointer;
  height: 100px;
}

.collection-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}
.collection-edit {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  color: white;
  padding: 0.5rem;
  border-radius: 0px 0px 10px 10px;
  font-weight: bold;
}
.collection-edit > .edit {
  position: absolute;
  background-color: black;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 10px;
}

.collection-edit > .delete {
  position: absolute;
  background-color: black;
  color: rgb(254, 1, 1);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 10px;
}
.collection-name {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 22%,
    rgba(255, 255, 255, 0) 100%
  );
  color: white;
  padding: 0.5rem;
  border-radius: 0px 0px 10px 10px;
  font-weight: bold;
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal container */
.custom-modal {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease;
}

/* Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Close button */
.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #888;
  cursor: pointer;
}

/* Body and Footer */
.modal-body {
  margin-bottom: 20px;
  text-align: center;
}
.modal-footer {
  display: flex;
  justify-content: space-between;
}
.modal-footer .btn {
  flex: 1;
  margin: 0 5px;
}

/* Animation for modal */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
