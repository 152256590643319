<template>
  <FullScreenLoader :isVisible="intialloading" />
  <div class="container setting-page wrapper">
    <TopButtons :buttons="['backbtn', 'title']" :options="topvarOptions" />

    <!-- Login Button / User Info -->
    <div
      v-if="userstore.user == undefined || userstore.user.email == undefined"
      class="text-center mb-4"
    >
      <button
        class="btn btn-dark w-100"
        style="border-radius: 25px"
        @click="goToLogin"
        v-ripple
      >
        Login
      </button>
    </div>
    <div v-else class="card mb-4 mt-2 p-3 information-div">
      <button
        class="btn btn-sm"
        style="
          color: black;
          position: absolute;
          top: 10px;
          right: 10px;
          border: black 1px solid;
        "
        v-if="!isEditing"
        @click="editProfile"
      >
        <i class="fa-solid fa-pencil"></i>
      </button>

      <!-- <div class="d-flex justify-content-center align-items-center mt-3">
        <img
          :src="user?.profileimage ?? profileImage"
          alt="Profile"
          class="rounded-circle me-3"
          width="200"
          height="200"
          @click="triggerImageUpload"
          style="cursor: pointer; object-fit: cover"
        />

        <input
          type="file"
          accept="image/*"
          ref="fileInput"
          class="d-none"
          @change="uploadImages"
        />
      </div> -->

      <div class="mx-2 mb-2 mt-2" style="color: red" v-if="error.name != ''">
        <span>{{ error.name }}</span> <br />
      </div>

      <table class="table" style="table-layout: fixed; word-wrap: break-word">
        <tbody>
          <tr>
            <th scope="row" style="width: 35%">Name</th>
            <td>
              <input
                type="text"
                class="form-control"
                v-model="editableUser.name"
                v-if="isEditing"
              />
              <span v-if="!isEditing">{{ editableUser.name }}</span>
            </td>
          </tr>
          <tr>
            <th scope="row" style="width: 35%">Email</th>
            <td>
              <input
                type="email"
                class="form-control"
                v-model="editableUser.email"
                v-if="isEditing"
                disabled
              />
              <span class="text-truncate-1" v-if="!isEditing">{{
                editableUser.email
              }}</span>
            </td>
          </tr>
          <tr>
            <th scope="row" style="width: 35%">Gender</th>
            <td>
              <select
                class="form-select"
                v-model="editableUser.gender"
                v-if="isEditing"
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              <span v-if="!isEditing" style="text-transform: capitalize">{{
                editableUser.gender
              }}</span>
            </td>
          </tr>
          <tr>
            <th scope="row" style="width: 35%">Date of Birth</th>
            <td>
              <input
                type="date"
                class="form-control"
                v-model="editableUser.dob"
                v-if="isEditing"
              />
              <span v-if="!isEditing">{{ editableUser.dob }}</span>
            </td>
          </tr>
          <tr>
            <th scope="row" style="width: 35%">Addresses</th>
            <td>
              <div
                class="p-2"
                style="border-radius: 10px; border: 1px black solid"
                v-for="add in addressList"
                v-bind:key="add"
              >
                <span>
                  {{ add.address1 }}, {{ add.address2 }}, {{ add.city }} ({{
                    add.postalcode
                  }})</span
                >
              </div>
              <br />
              <button
                class="btn btn-dark mt-2 round-button"
                @click="showadressmodal"
                v-if="isEditing"
              >
                Add More
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="d-flex justify-content-end mt-3">
        <!-- <button
          class="btn btn-secondary me-2"
          @click="toggleEdit"
          v-if="!isEditing"
        >
          Edit
        </button> -->
        <button
          class="btn btn-danger ms-2 round-button"
          @click="toggleEdit"
          v-if="isEditing"
        >
          Cancel
        </button>
        <button
          class="btn btn-dark ms-2 round-button"
          @click="updateUser"
          v-if="isEditing"
        >
          Save
        </button>
        <button
          v-if="!isEditing"
          class="btn btn-secondary round-button"
          @click="logout"
        >
          Logout
        </button>
      </div>
    </div>
    <!-- Settings Options (Second Group) -->
    <ul class="list-group mb-4">
      <li
        class="list-group-item d-flex justify-content-between align-items-center"
        @click="viewOurStory"
        style="
          border-bottom: 1px solid #d9d9d9;
          border-radius: 10px 10px 0px 0px;
        "
      >
        <span>What brands would you add?</span>
        <i class="fa-solid fa-chevron-right"></i>
      </li>
      <li
        class="list-group-item d-flex justify-content-between align-items-center"
        @click="privacypolicy"
        style="border-bottom: 1px solid #d9d9d9; border-radius: 0px 0px 0px 0px"
      >
        <span>Privacy Policy</span>
        <i class="fa-solid fa-chevron-right"></i>
      </li>
      <li
        class="list-group-item d-flex justify-content-between align-items-center"
        @click="termsandcon"
        style="border-bottom: 1px solid #d9d9d9; border-radius: 0px 0px 0px 0px"
        v-if="platform == 'web'"
      >
        <span>Term & Conditions</span>
        <i class="fa-solid fa-chevron-right"></i>
      </li>
      <li
        class="list-group-item d-flex justify-content-between align-items-center"
        @click="showpolicies"
        style="border-bottom: 1px solid #d9d9d9; border-radius: 0px 0px 0px 0px"
      >
        <span>Return Policies</span>
        <i class="fa-solid fa-chevron-right"></i>
      </li>
      <li
        class="list-group-item d-flex justify-content-between align-items-center"
        @click="aboutus"
        style="border-bottom: 1px solid #d9d9d9; border-radius: 0px 0px 0px 0px"
      >
        <span>About Us</span>
        <i class="fa-solid fa-chevron-right"></i>
      </li>
      <li
        class="list-group-item d-flex justify-content-between align-items-center"
        @click="deleteaccount"
        style="border-radius: 0px 0px 10px 10px"
      >
        <span>Delete Account</span>
        <i class="fa-solid fa-chevron-right"></i>
      </li>
    </ul>

    <div style="text-align: center" class="align-items-center mt-4">
      <button
        class="btn btn-dark btn-social"
        @click="social('fb')"
        style="border-radius: 50%"
      >
        <i class="fa-brands fa-facebook"></i>
      </button>
      <button
        class="btn btn-dark btn-social"
        @click="social('insta')"
        style="border-radius: 50px"
      >
        <i class="fa-brands fa-instagram"></i>
      </button>
      <button
        class="btn btn-dark btn-social"
        @click="social('tik')"
        style="border-radius: 50px"
      >
        <i class="fa-brands fa-tiktok"></i>
      </button>
    </div>
    <div style="height: 2vh"></div>

    <AddressDetailsForm
      :addressList="addressList"
      :cities="cities"
      :isVisible="isModalVisible"
      :closeModal="closeModal"
      @saveaddress="saveaddress"
    />

    <!-- Bottom Navigation Bar -->
  </div>

  <div v-if="showModal2" class="custom-modal-overlay" @click.self="closeModal2">
    <div class="custom-modal">
      <div class="modal-header">
        <h5>Confirm Action</h5>

        <span
          class="close-button"
          @click="closeModal"
          style="
            cursor: pointer;
            padding: 0.3rem;
            right: 2rem;
            top: 1.1rem;
            font-size: x-large;
          "
          >&times;</span
        >
      </div>
      <div class="modal-body">
        <p>Do you want to logout?</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary border-round" @click="closeModal2">
          Cancel
        </button>
        <button class="btn btn-danger border-round" @click="confirmClearCart2">
          Yes, Logout
        </button>
      </div>
    </div>
  </div>

  <div v-if="isModalOpen" class="modal-overlay">
    <div class="modal-content">
      <button @click="closeModal" class="close-button">✖</button>
      <h3>Shipping and Return Policy</h3>
      <div
        v-for="(brand, index) in localPolicies"
        :key="brand.vendor_id"
        class="brand-section"
      >
        <div @click="toggleBrand(index)" class="brand-label">
          {{ brand.vendorname }}
          <span style="float: right">{{ brand.isExpanded ? "▼" : "▲" }}</span>
        </div>
        <div v-if="brand.isExpanded" class="policy-details">
          <!-- <h4>Shipping Policy:</h4>
            <p>{{ brand.shippingPolicy }}</p> -->
          <h4>Return Policy:</h4>
          <div v-html="brand.return_policy"></div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="isbrandmodalopen" class="modal-overlay">
    <div class="modal-content">
      <button @click="closeModal" class="close-button">✖</button>
      <h3>What brands would you add?</h3>
      <div>
        <label for="brandfor">Suggested Brand</label>
        <input
          type="text"
          name="brandfor"
          id="brandfor"
          v-model="suggestbrandname"
          class="mb-2 form-control"
        />
        <button class="btn btn-dark" @click="suggestbrand" style="float: right">
          Suggest
        </button>
      </div>
    </div>
  </div>

  <ion-toast
    id="toast"
    trigger="open-toast"
    :is-open="isOpentoast"
    message="Your suggestion submitted to the wardrobe team."
    :duration="3000"
  ></ion-toast>
</template>

<script setup>
// Reactive state for user information
import AddressDetailsForm from "@/components/checkout/AddressDetailsForm.vue";
import FullScreenLoader from "@/components/common/FullScreenLoader.vue";
import { URLService } from "@/services/_url";
import { CityService } from "@/services/cityservice";
import { CommonService } from "@/services/commonservice";
import { UserService } from "@/services/userservice";
import { useUserStore } from "@/stores/userStore";
import { useVendorStore } from "@/stores/vendorStore";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { IonToast } from "@ionic/vue";
import { Browser } from "@capacitor/browser";
import TopButtons from "@/components/common/TopButtons.vue";
import { Device } from "@capacitor/device";

const usevendorStore = useVendorStore();

// const profileImage = ref(
//   "https://wardrobetheappimgtorage1.blob.core.windows.net/logo-container/pngtree-file-upload-icon-image_1344393.jpg"
// ); // Default image

// // File input reference (hidden)
// const fileInput = ref(null); // Open the file picker when image is clicked
// function triggerImageUpload() {
//   if (isEditing.value) fileInput.value.click();
// }

function confirmClearCart2() {
  showModal2.value = false;
  userstore.logout();
}
const platform = ref(null);
onMounted(async () => {
  try {
    const info = await Device.getInfo(); // eslint-disable-next-line
    platform.value = info.platform; // e.g., "ios", "android", "web"
  } catch (error) {
    console.error("Error getting device info:", error);
  }
});

const showModal2 = ref(false);

function closeModal2() {
  showModal2.value = false;
}

const toggleEdit = () => {
  isEditing.value = !isEditing.value;
};
const intialloading = ref(false);

// User info data
// const userInfo = ref({
//     name: 'Lakshitha Perera',
//     email: 'lakshithaperera04@gmail.com',
//     phone: '0772345678',
// });

const router = useRouter();
const user = ref(null);
const editableUser = ref({});
const userstore = useUserStore();
const isEditing = ref(false);
const error = ref({});

const editProfile = () => {
  isEditing.value = !isEditing.value;
};

// Check for logged-in user in localStorage
onMounted(async () => {
  await userstore.loadUserFromStorage();
  user.value = userstore.user;
  editableUser.value = userstore.user;
  let a = userstore.user.addresses;

  if (a == undefined || a == null || a == "") {
    addressList.value = [];
  } else {
    addressList.value = JSON.parse(a);
  }

  cities.value = (await CityService.getallcities()).sort((a, b) =>
    a.city > b.city ? 1 : -1
  );

  CommonService.getVendorPolicies().then(async (data) => {
    usevendorStore.setpolicies(data.data);
    localPolicies.value = data.data;
  });

  updateAppHeight(); // Set initial height
  window.addEventListener("resize", updateAppHeight); // Update on resize
});

const goToLogin = () => {
  router.push({ name: "Login" });
};

const logout = () => {
  showModal2.value = true;
};

const updateUser = async () => {
  intialloading.value = true;
  try {
    var url = URLService.updateprofile + "/" + user.value.usercode;
    let data = {
      name: editableUser.value.name,
      gender: editableUser.value.gender,
      dob: editableUser.value.dob,
      addresses: editableUser.value.addresses,
    };
    let response = await UserService.updateuser(url, data);
    intialloading.value = false;
    isEditing.value = false;
    if (response.status >= 200 && response.status < 400) {
      try {
        let _url = URLService.getme + "/" + user.value.usercode;
        UserService.getuser(_url).then((d) => {
          userstore.setLogin(d.data.data);
        });
      } catch (error) {
        console.log(error);
      }
    }
  } catch (error) {
    intialloading.value = false;
    isEditing.value = false;
  }
};

// Address Section
const addressList = ref([]);
const cities = ref([]);
const isModalVisible = ref(false);

const closeModal = () => {
  isModalVisible.value = false;
  isModalOpen.value = false;
  isbrandmodalopen.value = false;
};

const saveaddress = (data) => {
  var addresses = JSON.parse(editableUser?.value?.address ?? "[]");
  if (
    addresses == [] ||
    addresses == undefined ||
    addresses == "" ||
    addresses == null ||
    addresses == ""
  ) {
    let a = [];
    a.push(data);
    addresses = a;
  } else {
    if (data.name != "") {
      var a = addresses.filter((s) => {
        return s.name == data.name;
      });
      if (a.length > 0) {
        addresses[addresses.indexOf(a[0])] = data;
      }
    }
    addresses.push(data);
  }
  editableUser.value.addresses = JSON.stringify(addresses);
  closeModal();
};

const showadressmodal = () => {
  let a = userstore.user.addresses;
  if (a == undefined || a == null || a == "") {
    addressList.value = [];
  } else {
    addressList.value = JSON.parse(a);
  }
  if (isEditing.value) isModalVisible.value = true;
};

const isModalOpen = ref(false);
const localPolicies = ref([]);
const toggleBrand = (index) => {
  localPolicies.value[index].isExpanded =
    !localPolicies.value[index].isExpanded;
};

const showpolicies = async () => {
  await Browser.open({ url: "https://wardrobetheapp.com/returns.html" });
};
const aboutus = async () => {
  await Browser.open({ url: "https://wardrobetheapp.com/about.html" });
};

const isbrandmodalopen = ref(false);
const isOpentoast = ref(false);
const viewOurStory = () => {
  isbrandmodalopen.value = true;
};
const deleteaccount = () => {
  router.push({ name: "delete" });
};

const privacypolicy = async () => {
  await Browser.open({ url: "https://wardrobetheapp.com/privacy.html" });
};

const termsandcon = async () => {
  await Browser.open({ url: "https://wardrobetheapp.com/terms.html" });
};
const suggestbrandname = ref("");
const suggestbrand = () => {
  let url = URLService.suggestbrand;
  let data = {
    suggestions: JSON.stringify({
      name: suggestbrandname.value,
      username: editableUser.value.name ?? "",
    }),
  };
  try {
    CommonService.suggestservice(url, data);
  } catch (error) {
    console.log(error);
  }
  isbrandmodalopen.value = false;
  isOpentoast.value = true;
  setTimeout(() => {
    isOpentoast.value = false;
  }, 5000);
};
//

const social = async (type) => {
  switch (type) {
    case "fb":
      await Browser.open({
        url: "https://www.facebook.com/share/1Dyo9VZqPx/?mibextid=LQQJ4d",
      });
      break;
    case "insta":
      await Browser.open({
        url: "https://www.instagram.com/wardrobetheapp/profilecard/?igsh=MTk3c2NvbHViNWltdg==",
      });
      break;
    case "tik":
      await Browser.open({
        url: "https://www.tiktok.com/@wardrobetheapp?_t=8rSc9EM0WRT&_r=1",
      });
      break;
    default:
      break;
  }
};

const topvarOptions = { title: "Information" };

const updateAppHeight = async () => {

  const info = await Device.getInfo(); // eslint-disable-next-line
  let adjustedHeight = 0;
  if (info.platform == "ios")
    adjustedHeight = window.innerHeight - window.innerHeight * 0.15;
  else adjustedHeight =  window.innerHeight - window.innerHeight * 0.7;
  document.documentElement.style.setProperty(
    "--app-height",
    `${adjustedHeight}px`
  );
}
</script>

<style scoped>
.container {
  padding: 1rem;
}

.card {
  border-radius: 10px;
}

.list-group-item {
  cursor: pointer;
  border: 0px;
  background-color: #e2e2e296;
}

.navbar button i {
  font-size: 1.5rem;
}
.btn-dark {
  background-color: #000;
}

.btn-dark:hover {
  background-color: #333;
}

.round-button {
  border-radius: 50px;
  padding-left: 15px;
  padding-right: 15px;
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal container */
.custom-modal {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease;
}

/* Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Close button */
.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #888;
  cursor: pointer;
}

/* Body and Footer */
.modal-body {
  margin-bottom: 20px;
  text-align: center;
}
.modal-footer {
  display: flex;
  justify-content: space-between;
}
.modal-footer .btn {
  flex: 1;
  margin: 0 5px;
}

.information-div {
  background-color: #e2e2e296;
  border: 0px;
}

.information-div table {
  background-color: transparent !important;
}

table th,
td {
  background-color: transparent !important;
  border: 0px;
}
table tr {
  border: 0px;
}

.btn-social {
  width: 50px;
  height: 50px;
  padding: 0px;
  margin: 5px;
  font-size: x-large;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure modal is on top */
}

.modal-content {
  background-color: white;
  /* White background for modal */
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  /* Max width for modal */
  position: relative;
  height: 85vh;
  max-height: 85vh;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.brand-section {
  margin-top: 1rem;
}

.brand-label {
  background-color: #f1f1f1;
  /* Light background for brand label */
  padding: 0.5rem;
  border-radius: 5px;
  /* Rounded corners */
  cursor: pointer;
}

.policy-details {
  margin-top: 0.5rem;
  padding-left: 1rem;
  /* Indent for policy details */
}

.setting-page {
  overflow-y: scroll;
  padding-top: 1rem;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
}

.text-truncate-1 {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  cursor: pointer; /* Make it look clickable */
  white-space: normal;
}

.wrapper {
  height: var(--app-height);
}

.wrapper::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}
</style>
