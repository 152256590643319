<template>
  <div v-if="showModal" class="modal-backdrop">
    <div class="modal-content p-3">
      <div class="modal-header" style="justify-content: space-between">
        <h5 class="modal-title">Missing Item / Return Request</h5>

        <span
          class="close-button"
          @click="closeModal"
          style="
            cursor: pointer;
            padding: 0.3rem;
            right: 2rem;
            top: 1.1rem;
            font-size: x-large;
          "
          >&times;</span
        >
      </div>
      <div class="modal-body">
        <!-- Order Number -->
        <div class="mb-3">
          <div
            class="mb-3 position-relative orderNumber-modal"
            style="overflow-y: scroll"
          >
            <label for="orderNumber" class="form-label">Order Number</label>
            <input
              type="text"
              id="orderNumber"
              class="form-control"
              v-model="orderNumberInput"
              @input="filterOrderNumbers"
              @focus="showDropdown = true"
              placeholder="Type to search"
            />
            <ul v-if="showDropdown" class="dropdown-menu show">
              <li
                v-for="order in filteredOrderNumbers"
                :key="order"
                class="dropdown-item"
                @click="selectOrderNumber(order)"
              >
                {{ order }}
              </li>
            </ul>
          </div>
        </div>

        <!-- Store -->
        <div class="mb-3">
          <div class="mb-3 position-relative vendor-modal">
            <label for="vendor" class="form-label">Vendor</label>
            <input
              type="text"
              id="vendor"
              class="form-control"
              v-model="vendernumberinput"
              @input="filtervendors"
              @focus="showDropdown2 = true"
              placeholder="Type to search"
            />
            <ul v-if="showDropdown2" class="dropdown-menu show">
              <li
                v-for="order in filteredVendorNumbers"
                :key="order"
                class="dropdown-item"
                @click="selectvendor(order)"
              >
                {{ order }}
              </li>
            </ul>
          </div>
        </div>

        <!-- Date of Purchase -->
        <div class="mb-3">
          <label for="purchaseDate" class="form-label">Date of Purchase</label>
          <br />
          <span> {{ purchaseDate }}</span>
        </div>

        <div
          class="carousel-container"
          @touchstart="startSwipe"
          @touchend="endSwipe"
        >
          <!-- Product Slide -->
          <div
            v-for="(product, index) in items"
            :key="product.id"
            class="carousel-slide"
            :class="{ active: currentSlide === index }"
          >
            <!-- Product Selection Checkbox -->
            <div
              class="product-checkbox mb-3"
              style="
                position: absolute;
                z-index: 200;
                padding-top: 15px;
                padding-right: 15px;
                right: 0px;
              "
            >
              <input
                type="checkbox"
                :id="'product-' + product.id"
                v-model="product.isSelected"
                @change="toggleReasonSection(index)"
                style="border-radius: 50px"
                class="form-check-input large-checkbox"
              />
            </div>

            <!-- Product Details -->
            <div class="card mb-3">
              <img
                :src="product.image_url"
                class="card-img-top"
                alt="Product Image"
              />
              <div class="card-body">
                <h6 class="card-title">{{ product.product_name }}</h6>
                <p class="card-text">
                  Price: {{ product.selling_price }} | Size:
                  {{ product.size }} | Quantity: {{ product.quantity }}
                </p>
              </div>
            </div>

            <!-- Reason Section (enabled only when product is selected) -->
            <div class="mb-3" v-if="product.isSelected">
              <label class="form-label">Reason:</label>
              <div
                class="form-check"
                v-for="reason in reasonOptions"
                :key="reason"
                style="padding-left: 7rem; padding-right: 7rem"
              >
                <input
                  class="form-check-input"
                  style="border: 2px solid black"
                  type="radio"
                  :name="'reason-' + product.id"
                  :id="'reason-' + product.id + '-' + reason"
                  :value="reason"
                  v-model="product.reason"
                />
                <label
                  class="form-check-label"
                  :for="'reason-' + product.id + '-' + reason"
                >
                  {{ reason }}
                </label>
              </div>

              <!-- Other Reason Input -->
              <div v-if="product.reason === 'Other'">
                <input
                  type="text"
                  class="form-control mt-2"
                  placeholder="Specify if Other"
                  v-model="product.otherReason"
                />
              </div>
            </div>
          </div>

          <!-- Dot Navigation -->
          <div class="dot-navigation">
            <span
              v-for="(product, index) in items"
              :key="product.id"
              class="dot"
              :class="{ active: currentSlide === index }"
              @click="goToSlide(index)"
            ></span>
          </div>
        </div>

        <!-- <div class="carousel-container">
          <button @click="prevSlide" class="carousel-nav-button">‹</button>

          <div
            class="carousel-slide"
            v-for="(product, index) in items"
            :key="product.id"
            v-show="currentSlide === index"
          >
            <div
              class="product-checkbox mb-3"
              style="
                position: absolute;
                right: 50px;
                z-index: 200;
                border-radius: 50px;
                top: 5%;
              "
            >
              <input
                type="checkbox"
                :id="'product-' + product.id"
                v-model="product.isSelected"
                @change="toggleReasonSection(index)"
                class="form-check-input large-checkbox"
                style="border-radius: 50px"
              />
            </div>

            <div class="card mb-3">
              <img
                :src="product.image_url ?? '@/assets/300x400.svg'"
                class="card-img-top"
                alt="Product Image"
              />
              <div class="card-body">
                <h6 class="card-title">{{ product.product_name }}</h6>
                <p class="card-text">
                  Price: {{ product.selling_price }} | Size:
                  {{ product.size }} | Quantity: {{ product.quantity }}
                </p>
              </div>
            </div>

            <div class="mb-3" v-if="product.isSelected">
              <label class="form-label">Reason:</label>
              <div
                class="form-check"
                v-for="reason in reasonOptions"
                :key="reason"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  :name="'reason-' + product.id"
                  :id="'reason-' + product.id + '-' + reason"
                  :value="reason"
                  v-model="product.reason"
                />
                <label
                  class="form-check-label"
                  :for="'reason-' + product.id + '-' + reason"
                >
                  {{ reason }}
                </label>
              </div>

              <div v-if="product.reason === 'Other'">
                <input
                  type="text"
                  class="form-control mt-2"
                  placeholder="Specify if Other"
                  v-model="product.otherReason"
                />
              </div>
            </div>
          </div>

          <button @click="nextSlide" class="carousel-nav-button">›</button>
        </div> -->

        <!-- Select Product(s) -->
        <!-- <div class="mb-3">
          <label class="form-label">Select Product(s)</label>
          <div class="card mb-2">
            <img
              src="https://via.placeholder.com/100"
              class="card-img-top"
              alt="Product Image"
            />
            <div class="card-body">
              <h6 class="card-title">Denim Tube Dress</h6>
              <p class="card-text">Price: $80 | Size: S | Quantity: 1</p>
            </div>
          </div>
        </div> -->

        <!-- Reason -->
        <!-- <div class="mb-3">
          <label class="form-label">Reason:</label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="reason"
              id="missingItem"
              value="Missing Item"
              v-model="reason"
            />
            <label class="form-check-label" for="missingItem"
              >Missing Item</label
            >
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="reason"
              id="wrongItem"
              value="Wrong Item"
              v-model="reason"
            />
            <label class="form-check-label" for="wrongItem">Wrong Item</label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="reason"
              id="damagedItem"
              value="Damaged Item"
              v-model="reason"
            />
            <label class="form-check-label" for="damagedItem"
              >Damaged Item</label
            >
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="reason"
              id="other"
              value="Other"
              v-model="reason"
            />
            <label class="form-check-label" for="other">Other</label>
            <input
              type="text"
              v-if="reason === 'Other'"
              class="form-control mt-2"
              placeholder="Specify if Other"
              v-model="otherReason"
            />
          </div>
        </div> -->

        <ReturnPolicy :policies="policies" v-if="policies.length > 0" />

        <!-- Message -->
        <div class="mb-3">
          <label for="message" class="form-label">Message</label>
          <textarea
            id="message"
            class="form-control"
            rows="3"
            v-model="message"
          ></textarea>
        </div>

        <!-- Attach Image -->
        <div v-if="checkselection">
          <label class="form-label">Attach Image</label>
          <div class="image-upload-container">
            <div
              v-for="(image, index) in returnimages"
              :key="index"
              class="image-preview"
            >
              <img :src="image" alt="Uploaded" class="uploaded-image" />
            </div>

            <!-- Plus icon to add more images -->
            <div class="add-image-button" @click="triggerFileInput">
              <span>+</span>
            </div>

            <!-- Hidden file input to select an image -->
            <input
              type="file"
              ref="fileInput"
              accept="image/*"
              class="file-input"
              @change="handleFileUpload"
              style="display: none"
            />
          </div>
        </div>

        <!-- Submit Button -->
        <button
          @click="savereturn"
          type="submit"
          class="btn btn-dark w-100 mb-3"
          style="border-radius: 50px"
        >
          Submit
        </button>
      </div>
    </div>
  </div>

  <ion-toast
    id="toast"
    trigger="open-toast"
    :is-open="isOpen"
    message="You can upload up to 5 images"
    :duration="3000"
  ></ion-toast>
</template>

<script setup>
import {
  ref,
  defineEmits,
  defineProps,
  onMounted,
  onBeforeUnmount,
  computed,
} from "vue";
import { useUserStore } from "@/stores/userStore";
import { URLService } from "@/services/_url";
import { BookingService } from "@/services/bookinservice";
import { useVendorStore } from "@/stores/vendorStore";
import ReturnPolicy from "../product/ReturnPolicy.vue";
import { IonToast } from "@ionic/vue";
import { ProductService } from "@/services/productservice";

const usevendorStore = useVendorStore();
const orderNumberInput = ref(""); // Tracks input valuevendernumberinput
const vendernumberinput = ref(""); // Tracks input value
const filteredOrderNumbers = ref([]); // Holds filtered results
const filteredVendorNumbers = ref([]); // Holds filtered resultsfilteredVendorNumbers
const showDropdown = ref(false); // Controls dropdown visibility
const showDropdown2 = ref(false); // Controls dropdown visibilityshowDropdown2
const orderNumbers = ref([]); // Sample order numbers

const purchaseDate = ref("");
const message = ref("");
const vendors = ref([]);
const items = ref([]);

// Props and Events
defineProps({
  showModal: Boolean,
});
const emit = defineEmits(["close"]);

const closeModal = () => {
  emit("close");
};

// Function to filter order numbers based on input
const filterOrderNumbers = () => {
  const input = orderNumberInput.value.toLowerCase();
  filteredOrderNumbers.value = orderNumbers.value.filter((order) =>
    order.toLowerCase().includes(input)
  );
};

// Function to filter order numbers based on input
const filtervendors = () => {
  const input = vendernumberinput.value.toLowerCase();
  filteredVendorNumbers.value = vendors.value.filter((order) =>
    order.toLowerCase().includes(input)
  );
};

// Function to handle order selection
const selectOrderNumber = (order) => {
  orderNumberInput.value = order;
  let _order = bookings.value.filter((x) => {
    return x.order_number == order;
  });
  if (_order.length > 0) {
    let data = _order[0].items.map((x) => x.vendorname);
    let arr = data.filter(function (item, pos) {
      return data.indexOf(item) == pos;
    });
    let datedata = _order[0].created_at.split("T");
    purchaseDate.value = datedata[0];
    vendors.value = arr;
    vendernumberinput.value = "";
    filteredVendorNumbers.value = arr;
  }
  console.log(
    bookings.value.filter((x) => {
      return x.order_number == order;
    })
  );

  showDropdown.value = false; // Hide dropdown on selection
};

// Function to handle order selection
const selectvendor = (order) => {
  showDropdown2.value = false;
  vendernumberinput.value = order;
  let _order = bookings.value.filter((x) => {
    return x.order_number == orderNumberInput.value;
  });
  if (_order.length > 0) {
    items.value = _order[0].items.filter((x) => {
      return x.vendorname == order;
    });
    if (items.value.length > 0) {
      let vendorPolicies = usevendorStore.policies.filter((x) => {
        return x.vendor_id == items.value[0].vendor_id;
      });
      if (vendorPolicies.length > 0) policies.value = vendorPolicies;
    }
  }
};

const policies = ref([]);

// Function to handle outside click
const handleClickOutside = (event) => {
  const modalContent = document.querySelector(".orderNumber-modal");
  if (!modalContent?.contains(event.target)) {
    if (showDropdown.value == true) showDropdown.value = false; // Hide dropdown when clicking outside
  }
};

const handleClickOutside2 = (event) => {
  const modalContent = document.querySelector(".vendor-modal");
  if (!modalContent?.contains(event.target)) {
    if (showDropdown2.value == true) showDropdown2.value = false; // Hide dropdown when clicking outside
  }
};

const userStore = useUserStore();
const loading = ref(true);
const bookings = ref([]);
onMounted(async () => {
  if (
    userStore.user == undefined ||
    userStore.user == "" ||
    userStore.user == {} ||
    userStore.user == null
  ) {
    loading.value = false;
    return;
  } else {
    try {
      let url = URLService.getbookings.replace(
        "{userid}",
        userStore.user?.usercode
      );
      let response = await BookingService.getbookings(url);
      bookings.value = response.data.data;
      orderNumbers.value = bookings.value.map((x) => x.order_number);
      loading.value = false;
    } catch (error) {
      console.log(error);
      loading.value = false;
    }
  }

  document.addEventListener("click", handleClickOutside);
  document.addEventListener("click", handleClickOutside2);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
  document.removeEventListener("click", handleClickOutside2);
});

const reasonOptions = ["Missing Item", "Wrong Item", "Damaged Item", "Other"];
const currentSlide = ref(0);
let startX = ref(0);

// Toggle reason section based on selection
const toggleReasonSection = (index) => {
  if (!items.value[index].isSelected) {
    items.value[index].reason = "";
    items.value[index].otherReason = "";
  }
};

// Navigate to a specific slide
const goToSlide = (index) => {
  currentSlide.value = index;
};

// Handle swipe start
const startSwipe = (event) => {
  startX.value = event.touches[0].clientX;
};

// Handle swipe end
const endSwipe = (event) => {
  const endX = event.changedTouches[0].clientX;
  if (startX.value - endX > 50) {
    nextSlide();
  } else if (endX - startX.value > 50) {
    prevSlide();
  }
};

// Navigate to previous slide
const prevSlide = () => {
  currentSlide.value =
    currentSlide.value > 0 ? currentSlide.value - 1 : items.value.length - 1;
};

// Navigate to next slide
const nextSlide = () => {
  currentSlide.value =
    currentSlide.value < items.value.length - 1 ? currentSlide.value + 1 : 0;
};

const images = ref([]); // To store uploaded image URLs

// Function to trigger the hidden file input
const isOpen = ref(false);
const fileInput = ref(null);
const triggerFileInput = () => {
  if (images.value.length < 5) {
    // Limit the number of images if necessary
    fileInput.value.click();
  } else {
    isOpen.value = true;
  }
};

const returnimages = ref([]);
// Handle file upload and add to images list
const handleFileUpload = async (event) => {
  let arr = event.target.files;
  if (arr.length == 1) {
    const formData = new FormData();
    Array.from(arr).forEach((image, index) => {
      formData.append(`images[${index}]`, image);
    });

    try {
      let url = URLService.uploadreturnimage;
      const response = await ProductService.uploadReturnImage(url, formData);
      if (response.status >= 200 && response.status < 400) {
        if (response.data?.image_urls.length > 0)
          returnimages.value.push(response.data.image_urls[0]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       images.value.push(e.target.result); // Add the base64 string of the image
  //     };
  //     reader.readAsDataURL(file);
  //   }
};

const checkselection = computed(() => {
  let data = items.value.filter((x) => x.isSelected == true);
  if (data.length > 0) return true;
  else return false;
});

const savereturn = async () => {
  try {
    let url = URLService.addReturn;

    let selecteditems = items.value.filter((x) => x.isSelected == true);
    let data = {
      ordernumber: orderNumberInput.value,
      vendor_id: selecteditems.length > 0 ? selecteditems[0].vendor_id : "",
      vendorname: vendernumberinput.value,
      message: message.value,
      user_id: userStore.user?.usercode,
      images: JSON.stringify(returnimages.value),
      items: JSON.stringify(selecteditems),
    };
    await ProductService.createreturn(url, data);
    closeModal();
  } catch (error) {
    console.log(error);
  }
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content {
  background: #fff;
  width: 90%;
  max-width: 500px;
  border-radius: 8px;
  height: 80vh;
  overflow-y: auto;
  height: 85vh;
  max-height: 85vh;
}

.dropdown-menu {
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  background-color: #fff;
  z-index: 1000;
  margin-top: 0;
}

.dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.large-checkbox {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  color: black;
  background-color: black;
}

.form-check-input.large-checkbox {
  transform: scale(1.5);
}
.form-check-input:checked {
  background-color: black;
}

.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  max-width: 500px;
}

.carousel-slide {
  display: none;
  text-align: center;
}

.carousel-slide.active {
  display: block;
}

.large-checkbox {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-img-top {
  width: 100%;
  object-fit: cover;
}

.form-check-input.large-checkbox {
  transform: scale(1.5);
}

.dot-navigation {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #333;
}
.image-upload-container {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.image-preview {
  width: 50px;
  height: 50px;
  position: relative;
}

.uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.add-image-button {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  border-radius: 5px;
  cursor: pointer;
  font-size: 24px;
  color: #888;
  border: 1px solid #ddd;
}

.add-image-button:hover {
  background-color: #e2e2e2;
}

.file-input {
  display: none;
}
</style>
