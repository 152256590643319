<template>
  <div class="top-buttons">
    <button class="back-btn" v-if="buttons.includes('backbtn')" @click="goBack">
      <i class="fa-solid fa-chevron-left" style="font-size: large"></i>
    </button>
    <div class="header-title" v-if="buttons.includes('title')">
      <h1 class="text-center" style="font-weight: 600">
        {{ options?.title ?? "" }}
      </h1>
    </div>
    <div class="header-title" v-if="buttons.includes('image')">
      <img
        style="width: 200px !important"
        v-if="true != true"
        :src="options?.image"
        alt="Vendor Logo"
        class="vendor-logo"
      />
      <h1 class="text-center" style="font-weight: 600">
        {{ options?.name ?? "" }}
      </h1>
    </div>
    <div>
      <button style="opacity: 0" v-if="!buttons.includes('sharebtn')"></button>
      <button
        class="share-btn"
        v-if="buttons.includes('sharebtn')"
        @click="shareImage"
      >
        <i class="fas fa-share-alt"></i>
      </button>
      <button
        class="edit-btn"
        :class="isedit == true ? 'selected' : 'unselected'"
        v-if="buttons.includes('edit')"
        @click="$emit('clickedit')"
      >
        <i class="fas fa-pen"></i>
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
defineProps({
  buttons: Array,
  options: Object,
  isedit: Boolean,
});

defineEmits(["clickedit"]);

// Go back (placeholder function for navigation)
const goBack = () => {
  router.go(-1);
};

// Share image (placeholder function for sharing)
const shareImage = () => {
  // Add logic for sharing the current image (e.g., using Web Share API or custom action)
  alert("Share button clicked");
};
</script>

<style scoped>
/* Top Buttons */
.top-buttons {
  position: relative;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: flex-end; /* Align all items to the bottom */
  justify-content: space-between; /* Space items apart */
  padding: 0 15px;
  z-index: 2;
}

.header-title {
  flex-grow: 1; /* Allow the title to take up remaining space */
  display: flex;
  justify-content: center; /* Center the title */
  font-weight: 600;
}

.back-btn,
.share-btn,
.edit-btn {
  border: 0px;
  background-color: white;
  color: black;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.selected {
  background-color: #f0f0f0;
}

.back-btn:hover,
.share-btn:hover {
  background-color: #f0f0f0;
}

/* Adjust icon size */
.back-btn i,
.share-btn i {
  font-size: 18px;
}

/* Responsive for smaller screens */
@media (max-width: 576px) {
  .back-btn,
  .share-btn {
    font-size: 12px;
    padding: 6px 10px;
  }
}
</style>
