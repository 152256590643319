<!-- ProductPage.vue -->
<template>
  <FullScreenLoader :isVisible="intialloading" />
  <div class="product-page wrapper">
    <!-- Product Image Carousel -->
    <ProductCarousel :images="_product.images ?? []" />

    <div class="images-color-selection d-flex justify-content-center mx-3">
      <img
        class="color-selection-image"
        v-for="line in _product.lines"
        v-bind:key="line.color"
        :class="_selectedcolor == line.color ? 'selected-color' : ''"
        :src="line.baseimage"
        @click="selectedcolor(line.color)"
      />
    </div>
    <!-- Vendor Info -->
    <VendorInfo
      :vendor="_product.vendor"
      @add-to-collection="handleAddToCollection(_product)"
    />

    <!-- Product Details -->
    <ProductDetails :product="_product" />

    <!-- Stock and Selection Options -->
    <!-- <StockAndSelections :product="product" v-model:location="selectedLocation" v-model:color="selectedColor"
            v-model:size="selectedSize" v-model:quantity="selectedQuantity" /> -->
    <StockAndSelections
      v-if="loaded"
      :product="_product"
      :selecteduantity="_selectedquantity"
      @update:selectedquantity="($event) => (_selectedquantity = $event)"
      :selectedLocation="_selectedLocation"
      @update:selectedLocation="($event) => (_selectedLocation = $event)"
      :selectedcolor="_selectedcolor"
      @update:selectedcolor="($event) => (_selectedcolor = $event)"
      :selectedsize="_selectedsize"
      @update:selectedsize="($event) => (_selectedsize = $event)"
    />

    <!-- Add to Wardrobe Button -->

    <AddToWardrobeButton
      v-if="loaded"
      :product="_product"
      :selectedLocation="_selectedLocation"
      :selectedColor="_selectedcolor"
      :selectedSize="_selectedsize"
      :selectedQuantity="_selectedquantity"
    />

    <!-- Return Policy Section -->
    <ReturnPolicy :policies="policies" v-if="policies.length > 0" />
    <!-- Reviews Section -->
    <ReviewSection v-if="loaded" :reviews="_product.reviews" />

    <AddToCollection :modalshow="isshowmodalofcollection" :product="_product" />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import ProductCarousel from "@/components/product/ProductCarousel.vue";
import VendorInfo from "@/components/product/VendorInfo.vue";
import ProductDetails from "@/components/product/ProductDetails.vue";
import StockAndSelections from "@/components/product/StockAndSelections.vue";
import AddToWardrobeButton from "@/components/product/AddToWardrobeButton.vue";
import ReviewSection from "@/components/product/ReviewSection.vue";
import ReturnPolicy from "@/components/product/ReturnPolicy.vue";
import { useproductpageStore } from "@/stores/productPageStore";
import { ProductService } from "@/services/productservice";
import { URLService } from "@/services/_url";
import { useRoute, useRouter } from "vue-router";
import { useVendorStore } from "@/stores/vendorStore";
import FullScreenLoader from "@/components/common/FullScreenLoader.vue";
import AddToCollection from "@/components/collection/AddToCollection.vue";
import { ReportService } from "@/services/reportservice";

const useProductPage = useproductpageStore();
const usevendorStore = useVendorStore();
const route = useRoute();
const router = useRouter();
const loaded = ref(false);
const policies = ref([]);
const intialloading = ref(true);

onMounted(async () => {
  let id = route.params.id;
  if (id == undefined || id == null) {
    router.go(-1);
  }

  try {
    let url2 = URLService.reporturl + 'viewprod';
    ReportService.viewproduct(url2, id);
  } catch (error) {
    console.log(error);
  }

  var obj = JSON.parse(JSON.stringify(useProductPage.selectedproduct));
  if (obj == null || obj == undefined) {
    console.log("not ok");
  } else {
    if (obj.lines != undefined && obj.lines.length > 0 && obj.lines[0]) {
      obj.lines = JSON.parse(obj.lines[0]?.stock);
      _product.value = obj;
    }
  }

  var url = URLService.getproduct.replace(":id", id);
  var response = await ProductService.getProduct(url);
  if (response.status == 200) {
    if (response.data.lines[0]) {
      let lines = JSON.parse(response.data.lines[0]?.stock);
      response.data.lines = lines;
    } else {
      response.data.lines = [];
    }
    _product.value = response.data;
    if (_product.value.lines.length > 0) {
      setTimeout(() => {
        selectedcolor(_product.value.lines[0].color);
      }, 1000);
    }
    loaded.value = true;
  } else {
    router.go(-1);
  }

  intialloading.value = false;

  let vendorPolicies = usevendorStore.policies.filter((x) => {
    return x.vendor_id == _product.value.vendor_id;
  });
  if (vendorPolicies.length > 0) policies.value = vendorPolicies;

  updateAppHeight(); // Set initial height
  window.addEventListener("resize", updateAppHeight); // Update on resize
});

// Sample product data
const _product = ref({});

const isshowmodalofcollection = ref(false);
const handleAddToCollection = () => {
  isshowmodalofcollection.value = false;
  setTimeout(() => {
    isshowmodalofcollection.value = true;
  }, 200);
};

const _selectedLocation = ref({});
const _selectedcolor = ref("");
const _selectedsize = ref("");
const _selectedquantity = ref(1);

const selectedcolor = (color) => {
  _selectedcolor.value = {};
  let filtercolors = _product.value.lines.filter((x) => {
    return x.color == color;
  });
  if (filtercolors.length > 0) {
    _product.value.images = filtercolors[0].images;
  }
  _selectedcolor.value = color;
};

function updateAppHeight() {
  const adjustedHeight = window.innerHeight - window.innerHeight * 0.07;
  document.documentElement.style.setProperty(
    "--app-height",
    `${adjustedHeight}px`
  );
}
</script>

<style scoped>
.product-page {
  overflow-y: auto;
}
.product-page::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.product-page::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}
.color-selection-image {
  width: 60px;
  height: 80px;
  border-radius: 10px;
  margin: 0px 0.2rem;
}
.images-color-selection {
  overflow-x: scroll;
}

.images-color-selection::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.images-color-selection::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}
.selected-color {
  border: 2px black solid;
}
.wrapper {
  height: var(--app-height);
}

.wrapper::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}
</style>

<style>
.back-btn,
.share-btn {
  opacity: 50% !important;
}
</style>
