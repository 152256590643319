
<template>
  <FullScreenLoader :isVisible="intialloading" />
  <div class="wrapper" style="padding: 20px 0px;">
  <div class="login-container mx-4">
    <div class="card p-4 login-card">
      <img
        src="@/assets/logo-bh.png"
        alt="Delivery Items"
        width="100"
        height="100"
        style="align-self: center"
      />
      <h2 class="text-center mb-4">Login</h2>

      <div class="mb-3">
        <label for="email" class="form-label">Email address</label>
        <input
          type="email"
          class="form-control"
          id="email"
          placeholder="Enter email"
          v-model="email"
        />
        <div class="mx-2 mb-2 mt-2" style="color: red" v-if="error.email != ''">
          <span>{{ error.email }}</span> <br />
        </div>
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Password</label>
        <input
          type="password"
          class="form-control"
          id="password"
          placeholder="Password"
          v-model="password"
        />
        <div
          class="mx-2 mb-2 mt-2"
          style="color: red"
          v-if="error.password != ''"
        >
          <span>{{ error.password }}</span> <br />
        </div>
      </div>
      <div>
        <span style="float: right; padding-bottom: 10px; cursor: pointer" @click="forgotpassword"
          >Forgot Password</span
        >
      </div>
      <button
        class="btn btn-dark w-100"
        @click="handleLogin"
        style="border-radius: 50px" v-ripple
      >
        Login
      </button>
      <button
        class="btn btn-dark w-100 mt-3"
        @click="handleRegister"
        style="border-radius: 50px; background-color: #737373 !important"
      >
        Register
      </button>
    </div>
  </div>
</div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useUserStore } from "@/stores/userStore"; // Adjust the import path based on your directory structure
import { useRouter } from "vue-router";
import { URLService } from "@/services/_url";
import { UserService } from "@/services/userservice";
import FullScreenLoader from "@/components/common/FullScreenLoader.vue";

const userstore = useUserStore();
const email = ref("");
const password = ref("");
const error = ref({
  name: "",
  email: "",
  password: "",
});

const userStore = useUserStore();
const router = useRouter();
const intialloading = ref(false);

const handleLogin = async () => {
  try {
    if ((await validate()) == false) return;
    intialloading.value = true;

    var url = URLService.login;
    let response = await UserService.loginuser(url, {
      email: email.value,
      password: password.value,
    });

    if (response.status >= 200 && response.status < 400) {
      let userData = response.data.user;
      userStore.setLogin(userData);
      intialloading.value = false;
      router.push({ name: "Home" });
    } else {
      error.value.password = "Login failed, please contact administrator";
    }
  } catch (er) {
    intialloading.value = false;
    if (er.status > 0) {
      error.value.password = er.response.data.message;
    } else {
      er.message;
    }
  }
};
const handleRegister = () => {
  router.push({ name: "register" });
};

function validateEmail(email) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
}

const validate = () => {
  let validated = true;
  error.value.email = "";
  error.value.password = "";
  if (validateEmail(email.value) == false) {
    error.value.email = "Please enter a valid email";
    validated = false;
  }
  if (email.value == "" || email.value == undefined || email.value == null) {
    error.value.email = "Please enter your email";
    validated = false;
  }
  if (
    password.value == "" ||
    password.value == undefined ||
    password.value == null
  ) {
    error.value.password = "Please enter your password";
    validated = false;
  }
  return validated;
};

onMounted(() => {
  if (
    userstore.user?.email != null &&
    userstore.user?.email != "" &&
    userstore.user?.email != undefined
  )
    router.push({ name: "Home" });



updateAppHeight(); // Set initial height
window.addEventListener("resize", updateAppHeight); // Update on resize
});

const forgotpassword = () => {
  router.push({'name': 'forgotpassword'})
}


function updateAppHeight() {
  const adjustedHeight =
    window.innerHeight - window.innerHeight * 0.07 ;
  document.documentElement.style.setProperty(
    "--app-height",
    `${adjustedHeight}px`
  );
}
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Full height for mobile and web */
}

.login-card {
  width: 100%;
  max-width: 400px;
  /* Limit the width on larger screens */
  /* border-radius: 10px; */
  background-color: #fff;
  /* border: 1px solid #ddd; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.form-control {
  border: 1px solid #ddd;
  background-color: #f8f9fa;
}

.btn-dark {
  background-color: #333;
  border-color: #333;
}

.btn-dark:hover {
  background-color: #555;
  border-color: #555;
}

.text-danger {
  color: #dc3545;
}
  
.wrapper {
  height: var(--app-height);
  overflow-y: scroll;
}

.wrapper::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}
</style>
