<!-- VendorPage.vue -->
<template>
  <div class="loading-container">
    <div class="w-logo">
      <img
        src="@/assets/logo-bh.png"
        alt="Delivery Items"
        width="200"
        height="200"
      />
    </div>
    <!-- <div class="loading-text">Loading...</div> -->
  </div>
</template>

<script setup>
import { URLService } from "@/services/_url";
import { CommonService } from "@/services/commonservice";
import { onMounted, onBeforeMount } from "vue";
import { useFilterStore } from "@/stores/filterstore.js";
import { useRouter } from "vue-router";

const filterStore = useFilterStore();
const router = useRouter();
onBeforeMount(() => {
  if (getWithExpiration("commondata") == null) {
    localStorage.removeItem("commondata");
  }
});

onMounted(async () => {
  try {
    let _finaldata = {};
    if (
      !localStorage.getItem("commondata") ||
      localStorage.getItem("commondata") == null ||
      localStorage.getItem("commondata") == ""
    ) {
      let data = await CommonService.getcommondata(URLService.getcommondata);
      if (data.status == 200) {
        let brands = data.data.brands.map((x) => {
          return x.name;
        });
        let product = data.data.category.filter((x) => {
          if (x.parent_id == null || x.parent_id == 0 || x.parent_id == "") {
            x.children = data.data.category.filter((y) => {
              return y.parent_id == x.id;
            });
            return x.name;
          }
        });
        let events = data.data.events.map((x) => {
          return x.name;
        });
        let colors = data.data.colors.map((x) => {
          return x.name;
        });
        let images = data.data.images.map((x) => {
          return x.public_url;
        });
        _finaldata = { brands, product, events, colors, images};
        await storeWithExpiration("commondata", _finaldata, (60000 * 5));
      }
    } else {
      _finaldata = await getWithExpiration("commondata");
    }

    filterStore.setMainfilterData(_finaldata);
    filterStore.setmainimages(_finaldata);
    router.push({ name: "Home" });
  } catch (error) {
    console.log(error);
    // router.push({ name: 'Home' });
  }
});

const storeWithExpiration = (key, value, ttl) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

// Getting data and checking expiration
const getWithExpiration = (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) return null;

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};
</script>

<style scoped>
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.w-logo {
  perspective: 1000px;
  width: 200px;
  height: 200px;
  animation: myAnim 2s ease 0s infinite normal forwards;
}

.w-letter {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 120px;
  font-weight: bold;
  color: #000000;
  animation: rotate3d 2s infinite linear;
  transform-style: preserve-3d;
}

.loading-text {
  margin-top: 20px;
  font-size: 18px;
  color: #000000;
}

@keyframes rotate3d {
  0% {
    opacity: 0;
    transform: rotate(-540deg) scale(0);
  }

  100% {
    opacity: 1;
    transform: rotate(0) scale(1);
  }
}


@keyframes myAnim {
	0% {
		animation-timing-function: ease-out;
		transform: scale(1);
		transform-origin: center center;
	}

	10% {
		animation-timing-function: ease-in;
		transform: scale(0.91);
	}

	17% {
		animation-timing-function: ease-out;
		transform: scale(0.98);
	}

	33% {
		animation-timing-function: ease-in;
		transform: scale(0.87);
	}

	45% {
		animation-timing-function: ease-out;
		transform: scale(1);
	}
}
</style>
