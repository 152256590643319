import { useUserStore } from '@/stores/userStore';
import axios from 'axios'
// const baseurl = import.meta.env.VITE_APP_BaseURL
// axios.defaults.baseURL = baseurl

export const ReportService = {
    viewproduct(url, id) {
        const userstore = useUserStore();
        var a = userstore.user;
        if (a) {
            var birth = a?.dob?.split('-')
            let age = 0;
            let gender = '';
            if (birth && birth.length > 0) {
                let bdate = new Date(birth[0], (birth[1] - 1), birth[2], 0, 0, 0);
                age = this.getAge(bdate);
                gender = a?.gender
            }
            return axios.post(url, {
                id: id,
                age: age,
                gender: gender
            })
        }
    },
    getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    },
    async addproducttocart(url, id) {
        const userstore = useUserStore();
        var a = userstore.user;
        if (a) {
            var birth = a?.dob?.split('-')
            let age = 0;
            let gender = '';
            if (birth && birth.length > 0) {
                let bdate = new Date(birth[0], (birth[1] - 1), birth[2], 0, 0, 0);
                age = this.getAge(bdate);
                gender = a?.gender
            }
            return axios.post(url, {
                id: id,
                age: age,
                gender: gender
            })
        }
    },
    async createcollection(url, data) {
        return await axios.post(url, data)
    },
    async addedtocollection(url, data) {
        return await axios.post(url, data)
    },
    async getcollections(url) {
        return await axios.get(url)
    },
    async loginuser(url, data) {
        return await axios.post(url, data)
    },
    async deleteuser(url, data) {
        return await axios.post(url, data)
    },
    async getuser(url) {
        return await axios.get(url)
    },
    async updateuser(url, data) {
        return await axios.post(url, data)
    },
    async uploadImage(url, formData) {
        // '/api/mobile/me/uploadprofileimage'
        return await axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },
    async passwordreset(url, data) {
        return await axios.post(url, data)
    },
}
