<template>
  <FullScreenLoader :isVisible="intialloading" />
  <div class="wrapper" style="padding: 20px 0px">
    <div class="login-container mx-4">
      <div class="card p-4 login-card">
        <img
          src="@/assets/logo-bh.png"
          alt="Delivery Items"
          width="100"
          height="100"
          style="align-self: center"
        />
        <h2 class="text-center mb-4">Delete Account</h2>

        <div class="mb-3">
          <label for="email" class="form-label">Email address</label>
          <input
            type="email"
            class="form-control"
            id="email"
            placeholder="Enter email"
            v-model="email"
          />
          <div
            class="mx-2 mb-2 mt-2"
            style="color: red"
            v-if="error.email != ''"
          >
            <span>{{ error.email }}</span> <br />
          </div>
        </div>
        <button
          class="btn btn-dark w-100"
          @click="handleLogin"
          style="border-radius: 50px"
        >
          Request to delete Account
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { URLService } from "@/services/_url";
import { UserService } from "@/services/userservice";
import FullScreenLoader from "@/components/common/FullScreenLoader.vue";

const email = ref("");
const error = ref({
  name: "",
  email: "",
  password: "",
});

const intialloading = ref(false);

const handleLogin = async () => {
  try {
    intialloading.value = true;

    var url = URLService.deleteuser;
    let response = await UserService.deleteuser(url, {
      email: email.value
    });
    if (response.status >= 200) {
      error.value.email =
        "Request Submitted, Please contact administrator if you need to activate account";
    } else {
      error.value.email = "Request failed, please contact administrator";
    }
  } catch (er) {
    intialloading.value = false;
    if (er.status > 0) {
      error.value.password = er.response.data.message;
    } else {
      er.message;
    }
  }
};

onMounted(() => {
  updateAppHeight(); // Set initial height
  window.addEventListener("resize", updateAppHeight); // Update on resize
});

function updateAppHeight() {
  const adjustedHeight = window.innerHeight - window.innerHeight * 0.07;
  document.documentElement.style.setProperty(
    "--app-height",
    `${adjustedHeight}px`
  );
}
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Full height for mobile and web */
}

.login-card {
  width: 100%;
  max-width: 400px;
  /* Limit the width on larger screens */
  /* border-radius: 10px; */
  background-color: #fff;
  /* border: 1px solid #ddd; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.form-control {
  border: 1px solid #ddd;
  background-color: #f8f9fa;
}

.btn-dark {
  background-color: #333;
  border-color: #333;
}

.btn-dark:hover {
  background-color: #555;
  border-color: #555;
}

.text-danger {
  color: #dc3545;
}

.wrapper {
  height: var(--app-height);
  overflow-y: scroll;
}

.wrapper::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}
</style>

